<template>
    <div>
        <invoiceMenu v-if="invoiceIB" @changeActiveInvoice="changeActiveInvoice" :activeIndex='activeIndex' :invoicesList='invoiceIB'/>
        <div class="mainBody">
            <div class="headerWrapper">
                <h2 class="title">ARVEHALDUS</h2>
            </div>
            <div class="stockRecords">
                <!-- <div class="titles">
                    <h2 class="smallTitle">Arve vaade</h2>
                    <h2 class="smallTitle">Arve haldamine</h2>
                </div> -->
                <div class="invoiceManagement">
                    <div class="invoiceWrapper" v-if="activeInvoice">
                        <div class="invoiceHead">
                            <div class="centerWrapper">
                                <img 
                                    :src="require('@/assets/icons/arrow.svg')"
                                    @click="changeInvoice('prev')" 
                                />
                                <div class="text">
                                    <h3>{{ activeInvoice.reference }}</h3>
                                    <p>{{$dateLibrary.formatDate(activeInvoice.date, 'DD. MMM YYYY')}}</p>
                                </div>
                                <img 
                                    :src="require('@/assets/icons/arrow.svg')"
                                    @click="changeInvoice('next')"  
                                />
                            </div>
                            <div class="ctrButtons" v-if="!isEditingActive">
                                <div 
                                    class="singleBtn" 
                                    @click="manageInvoice()"
                                >
                                    <p>Halda arvet</p>
                                    <img 
                                        :src="require('@/assets/icons/tick.svg')" 
                                    />
                                    <!-- todo: halda arve vajutamise järgselt auto scroll contentini -->
                                </div>
                                <div 
                                    class="singleBtn" 
                                    style="background: #FF6969"
                                >
                                    <p>Eemalda arve</p>
                                    <img 
                                        :src="require('@/assets/icons/cross.svg')" 
                                    />
                                </div>
                            </div>
                        </div>

                        <invoiceManage 
                            @deactivateEditing='isEditingActive = false'
                            @nextInvoice="changeInvoice('next')"
                            :activeInvoice='activeInvoice' 
                            :isEditingActive='isEditingActive' 
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import invoiceMenu from '../components/menu/invoiceMenu.vue'
import invoiceManage from '../components/invoices/invoiceManage.vue'

export default {
    name: 'ledgerView',
    components: {
        invoiceMenu,
        invoiceManage
    },
    props: {
        closePopup: {}
    },
    data () {
        return {
            invoiceIB: null,
            activeInvoice: null,
            activeIndex: 0,
            isEditingActive: false
        }
    },
    methods: {
        manageInvoice() {
            this.isEditingActive = true
        },

        changeActiveInvoice(invoiceData, index) {
            this.activeInvoice = invoiceData
            this.activeIndex = index
            this.isEditingActive = false
        },

        changeInvoice(direction) {
            this.isEditingActive = false
            if(direction == 'prev') {
                if(this.activeIndex != 0) {
                    this.activeIndex --
                } else {
                    this.activeIndex = this.invoiceIB.length - 1
                }
            }

            if(direction == 'next') {
                if(this.activeIndex < this.invoiceIB.length - 1) {
                    this.activeIndex ++
                } else {
                    this.activeIndex = 0
                }
            }

            this.activeInvoice = this.invoiceIB[this.activeIndex]
        },

        getInvoices: async function() {
            fetch(this.$fetchIp+`/api/invoice/raw`, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res) {
                    this.invoiceIB = res.data.invoices
                    this.activeIndex = 0
                }
            })
        },
    },
    created() {
        this.getInvoices()
    }
}
</script>

<style scoped>
.mainBody {
    margin-left: 320px;
}
.title {
    margin: 0;
    font-family: 'quicksand', sans-serif;
    font-size: 42px;
    color: #b9b9b9;
}
.titles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.smallTitle {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'quicksand', sans-serif;
    font-size: 26px;
    color: rgb(75,75,75);
}
.headerWrapper {
    padding-top: 15px;
    padding-left: 100px;
    padding-right: 50px;
    padding-bottom: 15px;
    background: white;
    border-bottom: 1px solid rgb(220,220,220);
}
.stockRecords {
    padding-left: 100px;
    padding-right: 50px;
}
.ctrButtons {
    margin-top: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 380px;
}
.singleBtn {
    position: relative;
    background: #03C988;
    height: 35px;
    width: 185px;
    border-radius: 5px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin-top: 0px;
}
.singleBtn:last-child {
    margin-bottom: 0;
}
.singleBtn p {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50px;
    width: 150px;
    margin: auto;
    height: 23px;
    color: white;
    font-family: 'quicksand', sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: end;
}
.singleBtn img {
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 12px;
    width: 25px;
    height: 25px;
}
.invoiceManagement {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.invoiceWrapper {
    width: 1100px;
    margin-top: 20px;
}
.invoiceHead {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 1100px;
    margin-bottom: 20px;
}
.centerWrapper img {
    opacity: .3;
}
.centerWrapper img:last-child {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.centerWrapper img:hover {
    cursor: pointer;
    opacity: 1;
}
.centerWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.text {
    width: 420px;
}
.text p {
    margin: 0;
    margin-top: 5px;
    text-align: center;
    color: rgb(150,150,150);
}
.invoiceHead h3 {
    font-size: 40px;
    text-align: center;
    margin: 0;
    margin-top: 0px;
    font-family: 'quicksand', sans-serif;
    border-bottom: 1px solid rgb(220,220,220);
}
</style>
  