import { createApp } from 'vue'
import App from './App.vue'
import dateLibrary from './components/helpers/dateLibrary'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice';
import Button from 'primevue/button';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import ToastService from 'primevue/toastservice';
const app = createApp(App)
app.use(ToastService);

app.config.globalProperties.$fetchIp = process.env.VUE_APP_MAIN_URL
app.config.globalProperties.$storiumIp = process.env.VUE_APP_OT_API_URL

app.component('ButtonPrime', Button);
app.use(PrimeVue)
app.use(ConfirmationService)

app.config.globalProperties.$dateLibrary = dateLibrary

app.mount('#app')