<template>
    <div class="wrapper">
        <div class="header">
            <p class="headerTitle">LAOARVED</p>
            <div class="buttons">
                <div class="singleButton">
                    <p>Ostuarved</p>
                </div>
                <div class="singleButton">
                    <p>Müügiarved</p>
                </div>
            </div>
        </div>
        <div class="invoicesWrapper">
            <div 
                class="singleInvoice"
                :class="{ 'singleInvoiceActive': index == activeIndex }"
                v-for="(element, index) of invoicesList" :key="element.id"
                @click="viewInvoice(element, index)"
            >
                <div class="row">
                    <p style="font-size: 16px">{{element.idInvoice}}</p>
                    <p style="color: rgb(150,150,150)">{{$dateLibrary.formatDate(element.date, 'DD. MMM')}}</p>
                </div>
                <div class="row">
                    <p style="color: steelblue">{{element.supplierName}}</p>
                    <p style="font-size: 16px; margin-top: -2px; color: rgb(25,25,25)">{{element.totalSum}} €</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'invoiceMenu',
    components: {
    },
    props: {
        invoicesList: {},
        activeIndex: {
            default: 0
        }
    },
    data () {
        return {
            activeInvoice: null,
            invoiceIB: null,
            invoiceOB: null
        }
    },
    methods: {
        changeCurrentTab(nextTab) {
            this.activeTab = nextTab
            this.$emit('changeTab', nextTab)
        },

        viewInvoice(invoiceData, index) {
            this.activeInvoice = invoiceData
            this.$emit('changeActiveInvoice', invoiceData, index)
        },

        getInvoices: async function() {
            // fetch(this.fetchIp+'/invoices', {
            //     method: 'GET',
            //     headers: {
            //         'Content-type':'application/json'
            //     },
            //     credentials: 'include',
            // })
            // .then((res) => res.json()) 
            // .then((res) => {
            //     console.log(res)
                

            // })
            this.invoiceOB = [
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'Madis Tamm',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'UAB Lithu',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2233',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'UAB Lithu',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'UAB Lithu',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'UAB Lithu',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'UAB Lithu',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'UAB Lithu',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'UAB Lithu',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'Villu Tamme',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
                {
                    idInvoice: 97755,
                    invoiceNumber: 'ET-2912023',
                    comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                    createdAt: '2023-10-23T10:10:54.410Z',
                    registeredAt: '2023-01-10T21:17:48.847Z',
                    mainDate: '2023-06-24T00:42:39.317Z',
                    client: {
                        name: 'Madis Tamm',
                        phone: '1-346-260-3548',
                        email: 'Rocio82@gmail.com',
                        homePageUrl: 'http://katarina.com',
                    },
                    product: {
                        name: 'MiniSPA',
                        idProduction: 'CUPX-2213',
                        totalSum: 6702.63
                    }
                },
            ]
            this.invoiceIB = [
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97755,
                        invoiceNumber: 'ET-2912023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'AS K-Rauta',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97756,
                        invoiceNumber: 'ET-2412023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'Puumarket OÜ',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    },
                    {
                        idInvoice: 97757,
                        invoiceNumber: 'ET-1112023',
                        comboId: '7qj9n50fvzrcaalc5xzh5jl0o6dyro',
                        createdAt: '2023-10-23T10:10:54.410Z',
                        registeredAt: '2023-01-10T21:17:48.847Z',
                        mainDate: '2023-06-24T00:42:39.317Z',
                        supplier: {
                            idSupplier: 59109,
                            name: 'Altair Group OÜ',
                            phone: '1-346-260-3548',
                            email: 'Rocio82@gmail.com',
                            homePageUrl: 'http://katarina.com',
                            createdAt: '2023-10-23T10:10:54.415Z'
                        },
                        itemList: [
                            {
                                sku: 'dolorem',
                                name: 'nulla omnis',
                                unit: 'tk',
                                quantity: 19,
                                unitPrice: 52.77,
                                totalSum: 1002.63
                            },
                            {
                                sku: 'nihil',
                                name: 'rerum rerum',
                                unit: 'tk',
                                quantity: 34,
                                unitPrice: 819.7,
                                totalSum: 27869.8
                            },
                            {
                                sku: 'vero',
                                name: 'odit similique',
                                unit: 'tk',
                                quantity: 50,
                                unitPrice: 282.89,
                                totalSum: 14144.5
                            }
                        ]
                    }
            ]

            this.$emit('changeActiveInvoice', this.invoiceIB[0], 0)
        },
    },
    created() {
        this.$emit('changeActiveInvoice', this.invoicesList[0], 0)
    }
}
</script>

<style scoped>
.wrapper {
    position: fixed;
    background: white;
    width: 320px;
    height: 91vh;
    border-right: 1px solid rgb(230,230,230);
}
.header {
    position: relative;
    height: 83px;
    border-bottom: 1px solid rgb(220,220,220);
}
.headerTitle {
    margin: 0;
    padding-top: 10px;
    padding-left: 12px;
    font-size: 24px;
    color: rgb(150,150,150);
    font-family: 'quicksand', sans-serif;
    font-weight: bold;
}
.buttons {
    position: absolute;
    bottom: 10px;
    width: 95%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}
.singleButton {
    border: 1px solid rgb(220,220,220);
    border-radius: 5px;
    width: 135px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: rgb(100,100,100);
}
.singleButton p {
    margin: 0;
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
}
.singleButton:first-child {
    background: #03C988;
    color: white;
    border: 1px solid #03C988;
}
.invoicesWrapper {
    overflow-y: auto;
    scrollbar-width: thin;
    height: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}
.singleInvoice {
    border: 1px solid rgb(218, 231, 228);
    background: rgb(252,252,252);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 85%;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
}
.singleInvoice:hover {
    cursor: pointer;
    background: rgb(245,245,245);
}
.singleInvoiceActive {
    background: rgb(239, 253, 251);
    border: 1px solid rgb(23, 209, 163);
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.row:first-child {
    margin-bottom: 20px;
}
.row p {
    margin: 0;
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
    color: rgb(75,75,75);
}
</style>
