<template>
    <div class="wrapper">
        <div class="padding" v-if="existingItemList">
            <div class="headerContainer" style="display: flex; justify-content: space-between">
                <p class="mainTitle">Arve Nr. <span style="color: rgb(100,100,100)">{{ activeInvoice.reference }}</span></p>
                <p style="color: rgb(150,150,150)">(ID: {{ activeInvoice.reference }})</p>
            </div>
            <div style="display: flex; justify-content: space-between">
                <div class="dataContainer">
                    <p class="bigTitle">Tarnija andmed</p>
                    <div style="display: flex; justify-content: space-between">
                        <div>
                            <p class="smallTitle">Nimi</p>
                            <p class="dataText">{{ activeInvoice.supplierName }}</p>
                            <p class="smallTitle">Tarnija alates</p>
                            <p class="dataText">{{$dateLibrary.formatDate(activeInvoice.mainDate, 'MMMM YYYY')}}</p>
                        </div>
                        <div>
                            <p class="smallTitle">Telefon</p>
                            <p class="dataText">+372 5545403</p>
                            <p class="smallTitle">E-post</p>
                            <p class="dataText">test@tarnija.ee</p>
                        </div>
                    </div>
                </div>
                <div class="dataContainer" style="text-align: end">
                    <p class="bigTitle">Arve andmed</p>
                    <div style="display: flex; justify-content: space-between">
                        <div>
                            <p class="smallTitle">Tooteid</p>
                            <p class="dataText">{{Math.round(existingItemList.itemList.reduce((total, obj) => total + Number(obj.quantity), 0))}} tk</p>
                            <p class="smallTitle">Väärtus</p>
                            <p class="dataText">{{activeInvoice.totalSum}} €</p>
                        </div>
                        <div>
                            <p class="smallTitle">Arve kuupäev</p>
                            <p class="dataText">{{$dateLibrary.formatDate(activeInvoice.date, 'DD. MMMM YYYY')}}</p>
                            <p class="smallTitle">Lattu saadetud</p>
                            <p class="dataText">8 päeva tagasi</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="itemListContainer" v-if="!showStockProductList">
                <p class="bigTitle">Toodete nimekiri</p>

                <div class="index">
                    <p>Kategooria (Tootekood)</p>
                    <p>Nimetus</p>
                    <p>Kogus</p>
                    <p>Ühik</p>
                    <p>Ühikuhind</p>
                    <p>Hind kokku</p>
                </div>
                <p class="errorMessage" :class="{ 'errorMessageActive': errorMsg }">{{ errorMsg }}</p>
                <div class="singleItem" v-for="(element, index) of existingItemList.itemList" :key="element.id">
                    
                    <div class="firstItem" v-if="isEditingActive">
                        <span v-show="!newItemList[index].isNewProduct">
                            <Dropdown
                                class="dropdown"
                                v-model="selectedCategory[index]"
                                id={${index}}
                                :options="productTags"
                                inputClass="dropInput"
                                showClear
                                optionLabel="name"
                                placeholder="Vali tootele kategooria"
                            />

                            <span style="display: flex; justify-content: space-between" v-if="selectedCategory[index]">
                                <AutoComplete
                                    class="autoComplete"
                                    :class="{ 'autoCompleteWidth': productCodeValue[index] }"
                                    v-model="productCodeValue[index]"
                                    id={${index}}
                                    dropdown
                                    showClear
                                    forceSelection
                                    inputClass="autoCompleteInput"
                                    :suggestions="suggestionList"
                                    @complete="search($event, index)"
                                    :field="(item) => item.name"
                                    placeholder='Otsi toodet'
                                    :emptySearchMessage="'« Toodet ei leitud, sisestamiseks tuleb lattu luua uus toode! »'"
                                />

                                <dynamicPopup
                                    @addNewProductToList='addNewProductToList'
                                    ref="popupRef"
                                    :productCode="productCodeValue"
                                    :index="index"
                                    :productData="element"
                                    :productTags="productTags"
                                />
                            </span>
                        </span>
                        <div class="confirmedNewProduct" v-if="newItemList[index].isNewProduct">
                            <ButtonPrime class="cancelBtn" @click="removeNewProductFromList(index)" style="background: #4868D1; color: white" rounded icon="pi pi-times" severity="info" aria-label="Cancel" />
                            <span class="newProductTitle">Uus tootetüüp</span>
                            <span>{{ selectedCategory[index].name }}</span>
                            <br>
                            <span>{{ newItemList[index].name }}</span>
                        </div>
                    </div>
                    <div class="firstItem" v-else>
                        <span class="noItemsMsg" style="color: white"><i class="pi pi-file-import" style="margin-right: 7px"></i>Lattu kandmata</span>
                    </div>
                    <p>{{element.name}}</p>
                    <p>{{element.quantity}}</p>
                    <p>{{element.unit}}</p>
                    <p>{{element.unitPrice}} €</p>
                    <p>{{element.totalSum}} €</p>
                    <i class="successIcon pi pi-check" :class="{ 'successIconActive': loadingAnimation }" :style="{ 'animation-delay': (200 * (index * 2)) + 'ms' }"></i>
                </div>
            </div>

            <stockRecordReceipt :stockRecordData='someStockRecordData' v-else />

            <div class="ctrButtons" v-if="isEditingActive">
                <div
                    class="singleBtn"
                    @click="cancelEditing()"
                >
                    <p>Kinnita sissekanded</p>
                    <img
                        :src="require('@/assets/icons/tick.svg')"
                    />
                </div>
                <div
                    class="singleBtn"
                    style="background: gray"
                    @click="cancelEditing()"
                    label="Confirm" id="confirmButton"
                >
                    <p>Tühista muudatused</p>
                    <img
                        :src="require('@/assets/icons/cross.svg')"
                    />
                </div>
            </div>
            <!-- <div class="ctrButtons" v-if="isEditingActive">
                <div 
                    class="singleBtn"
                    style="width: 170px; margin-left: auto"
                    @click="postStockRecord()"
                >
                    <p style="right: 32px">Järgmine arve</p>
                    <i class="pi pi-chevron-right"></i>
                </div>
            </div> -->
        </div>
    </div>
    <successMsg v-if="successMsg" />
</template>

<script>
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import 'primevue/resources/themes/viva-light/theme.css';
import dynamicPopup from '@/components/primeVue/dynamicPopup.vue'
import stockRecordReceipt from '@/components/invoices/stockRecordReceipt.vue'
import successMsg from '@/components/invoices/successMsg.vue'


export default {
    emits: ['addNewProductToList'],
    name: 'invoiceManagement',
    components: {
        AutoComplete,
        Dropdown,
        dynamicPopup,
        stockRecordReceipt,
        successMsg
    },
    props: {
        activeInvoice: {},
        isEditingActive: {}
    },
    watch: {
        activeInvoice() {
            this.getInvoiceItems()
        },
        isEditingActive(newValue) {
            if(!newValue) {
                this.selectedCategory = {}
                this.productCodeValue = {}
                this.errorMsg = null
            } else {
                this.newItemList = JSON.parse(JSON.stringify(this.existingItemList.itemList))
            }
        }
    },

    data () {
        return {
            visible: false,
            existingItemList: null,
            selectedCategory: [],
            productCodeValue: {},
            productTags: [],
            productsByTagsFetched: false,
            activeProductTag: null,
            productList: [
                {
                    name: 'klaas 520Ø',
                    code: 'AKEN'
                },
                {
                    name: 'tulekindel klaas 250*170*4',
                    code: 'AKEN'
                },
                {
                    name: 'Poolkuuklaasid familyle',
                    code: 'AKEN'
                },
                {
                    name: 'puraviku tagaseina klaas tk',
                    code: 'AKEN'
                },
                {
                    name: 'kuusesaematerjal 28*42*6',
                    code: 'PUIT'
                },
                {
                    name: 'Aquaprimer 2900-02-Base T (termokollane)',
                    code: 'PUIT'
                },
                {
                    name: 'Harvia M3',
                    code: 'AHI'
                },
                {
                    name: 'kerisekivid',
                    code: 'AHI'
                },
                {
                    name: 'Termokahanev toru H-5(3x)-F-6/2-1,22M',
                    code: 'MUU'
                },
                {
                    name: 'ristkihtpuitplaat A/B kuusk 27*2050*5000mm',
                    code: 'PLAAT'
                },
                {
                    name: 'keermelatt M12',
                    code: 'Kinnitusvahend'
                },
            ],
            suggestionList: [],
            newItemList: []
        }
    },
    methods: {

        addNewProductToList(productName, index) {
            //
            // const product = [{ productName, index }]
            console.log('addNewProductsToList', productName, index)
            this.newItemList[index].isNewProduct = true
            this.newItemList[index].index = index
            // this.newItemList[index].idProductTag = idProductTag
            this.newItemList[index].name = productName
            // this.newItemList[index].tagName = tagName
            console.log(this.newItemList, 'newItemList')
        },

        removeNewProductFromList(index) {
            this.selectedCategory.splice(index, 1)
            this.newItemList[index].isNewProduct = false
        },

        toggle() {
            this.$nextTick(() => {
                this.$refs.popupRef.toggle();
            });
        },

        cancelEditing() {
            this.selectedCategory = {}
            this.productCodeValue = {}
            this.errorMsg = null
            this.$emit('deactivateEditing')
        },

        getProductTags() {
            fetch(this.$fetchIp+`/api/tags/product`, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                this.productTags = (res.data)
                ? res.data.tags
                : []
            })
        },

        getInvoiceItems() {
            fetch(this.$fetchIp+`/api/invoice/raw?idInvoice=${this.activeInvoice.idInvoice}`, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                this.existingItemList = res.data.invoices
                this.newItemList = JSON.parse(JSON.stringify(this.existingItemList.itemList))
            })
        },

        getProductsByTags(idSearchTag) {
            return new Promise((resolve, reject) => {
                fetch(`${this.$fetchIp}/api/products?idProductTag=${idSearchTag}`, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                    },
                    credentials: 'include',
                })
                .then((res) => res.json())
                .then((body) => {
                    console.log(body);
                    if (body.data) {
                        const { data: { products } } = body;
                        console.log(products, 'products');
                        resolve(products);
                    } else {
                        this.suggestionList = [{ name: 'Puudub' }];
                        resolve([]);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
            });
        },

        search(event, index) {
            console.log(index, 'index')
            // this has to query activeProductTag
            // in order to
            const query = event.query;
            if(!query && !this.productsByTagsFetched.length) {
                console.log('dropDownCase')
                const idSearchTag = this.selectedCategory[index].idProductTag
                
                this.getProductsByTags(idSearchTag)
                    .then(products => {
                        this.suggestionList = null
                        this.suggestionList = products.map((product) => {
                            return { 
                                name: product.name.toLowerCase(),
                                idProduct: product.idProduct
                            }
                        }); 
                        this.productsByTagsFetched = this.suggestionList
                })
            } else if (query && this.productsByTagsFetched.length) {
                if(this.selectedCategory[index].idProductTag !== this.productsByTagsFetched[0].idProduct) {
                    this.getProductsByTags(this.selectedCategory[index].idProductTag)
                    .then(products => {
                        this.suggestionList = null
                        this.suggestionList = products.map((product) => {
                            return { 
                                name: product.name.toLowerCase(),
                                idProduct: product.idProduct
                            }
                        })
                        this.suggestionList = this.productsByTagsFetched.filter((product) =>
                            product.name.toLowerCase().includes(event.query.toLowerCase())
                        );
                        this.productsByTagsFetched = this.suggestionList
                    })
                } else {
                    console.log('query: ', query)
                    this.suggestionList = this.productsByTagsFetched.filter((product) =>
                        product.name.toLowerCase().includes(event.query.toLowerCase())
                    );
                }
                
            } else {
                 const idSearchTag = this.selectedCategory[index].idProductTag
                
                this.getProductsByTags(idSearchTag)
                    .then(products => {
                        this.suggestionList = null
                        this.suggestionList = products.map((product) => {
                            return { 
                                name: product.name.toLowerCase(),
                                idProduct: product.idProduct
                            }
                        }); 
                        this.productsByTagsFetched = this.suggestionList
                })
            }
        },

        postStockRecord(productsToCreate, recordsToCreate) {
            //
            const stockRecordDataBody = {
                productsToCreate,
                recordsToCreate
            }
            console.log(stockRecordDataBody, 'stockRecordDataBody')
            fetch(this.$fetchIp+`/api/records`, {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: stockRecordDataBody
            })
            .then((res) => res.json())
            .then((res) => {
                console.log(res, 'res.postStockRecordBody')
            })


        }
    },
    created() {
        this.getInvoiceItems()
        this.getProductTags()
    }
}
</script>

<style scoped>

.wrapper {
    width: 1100px;
    background: white;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 5px;
    margin-bottom: 50px;
}
.padding {
    padding: 15px 50px 10px 50px;
}
.headerContainer {
    margin-bottom: 10px;
}
.mainTitle {
    font-size: 30px;
    margin: 0;
    margin-top: 10px;
    color: rgb(50,50,50);
}
.ctrButtons {
    margin-left: auto;
    padding-top: 20px;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 480px;
    border-top: 1px solid rgb(220, 220, 220);
}
.singleBtn {
    position: relative;
    background: #03C988;
    height: 35px;
    width: 235px;
    border-radius: 5px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin-top: 0px;
}
.singleBtn:last-child {
    margin-bottom: 0;
}
.singleBtn p {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50px;
    width: 230px;
    margin: auto;
    height: 23px;
    color: white;
    font-family: 'quicksand', sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: end;
}
.singleBtn i {
    margin-left: auto;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
}

.singleBtn img {
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 12px;
    width: 25px;
    height: 25px;
}
.bigTitle {
    font-size: 24px;
    margin: 0;
    margin-top: 10px;
    padding-bottom: 5px;
    color: rgb(50,50,50);
    border-bottom: 1px solid rgb(220, 220, 220);
}
.dataContainer {
    width: 46.7%;
}
.dataContainer:first-child {
    padding-right: 25px;
    border-right: 1px solid rgb(220, 220, 220);
}
.dataContainer:last-child {
    padding-left: 25px;
    border-left: 1px solid rgb(220, 220, 220);
}
.smallTitle {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 18px;
    color: steelblue;
}
.dataText {
    margin: 0;
    margin-bottom: 20px;
    font-size: 16px;
    color: rgb(100,100,100);
    font-family: 'DM sans', sans-serif;
}

.itemListContainer {
    position: relative;
    margin-top: 25px;
    padding-bottom: 25px;
}
.index {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    padding-bottom: 15px;
}
.index p {
    width: 14%;
    margin: 0;
    color: rgb(65,65,65);
    font-size: 14px;
    text-align: center;
    font-family: 'DM sans', sans-serif;
    text-transform: uppercase;
}
.index p:nth-child(1) {
    width: 28%;
    text-align: start;
}
.index p:nth-child(2) {
    width: 35%;
}
.index p:nth-child(3), .index p:nth-child(4) {
    width: 7%;
}
.index p:nth-child(6) {
    width: 9%;
    text-align: end;
}
.singleItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid rgb(235,235,235);
    height: 100px;
}
.singleItem:last-child {
    border: none;
}
.singleItem p {
    width: 14%;
    margin: 0;
    color: rgb(100,100,100);
    text-align: center;
    font-family: 'DM sans', sans-serif;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
}
.successIcon {
    position: absolute;
    margin-top: 32px;
    margin-left: -35px;
    background: #03C988;
    color: white;
    padding: 5px;
    border-radius: 100px;
    font-weight: bold;
    opacity: 0;
}
.successIconActive {
    -webkit-animation: myAnimation 1s ease-in-out forwards;
            animation: myAnimation 1s ease-in-out forwards;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.firstItem {
    margin-top: auto;
    margin-bottom: auto;
    width: 28%;
    text-align: start;
}
.noItemsMsg {
    background: #FF9130;
    color: white;
    border-radius: 10px;
    padding: 4px 12px 3px 7px;
}
.singleItem p:nth-child(2) {
    width: 35%;
}
.singleItem p:nth-child(3), .singleItem p:nth-child(4) {
    width: 7%;
}
.singleItem p:nth-child(6) {
    text-align: end;
    width: 9%;
}
.autoComplete {
    width: 60%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.autoCompleteWidth {
    width: 100%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.dropdown {
    width: 100%;
}
.confirmedNewProduct {
    position: relative;
    width: 280px;
    padding-top: 25px;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-right: 10px;
    background: white;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 5px;
    background: rgb(245,245,245);
}
.confirmedNewProduct span:nth-child(3) {
    font-size: 22px;
    font-weight: bold;
    font-family: 'quicksand', sans-serif;
    text-transform: capitalize;
}
.confirmedNewProduct span:nth-child(4) {
    font-size: 12px;
}
.cancelBtn {
    position: absolute;
    height: 30px;
    width: 30px;
    right: -12px;
    top: -12px;
}
.newProductTitle {
    position: absolute;
    left: 10px;
    top: 5px;
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
    color: #FF9130;
}
.errorMessage {
    position: absolute;
    top: 25px;
    width: 1000px;
    background: red;
    color: white;
    font-size: 18px;
    padding-left: 15px;
    border-radius: 0px 0px 5px 5px;
}
.errorMessageActive {
    -webkit-animation: myAnimation2 .2s ease-in-out forwards;
            animation: myAnimation2 .2s ease-in-out forwards;
}

@-webkit-keyframes myAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes myAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes myAnimation2 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}
@keyframes myAnimation2 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}
</style>


<style>
.p-autocomplete {
    height: 35px;
    border: 2px solid rgb(222, 222, 222);
    border-radius: 7px;
}
.p-autocomplete:focus {
    border: 2px solid #bbc7ee
}
/* .p-dropdown {
    height: 30px;
} */
.p-dropdown {
    margin-bottom: 10px;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 2rem;
}
.p-dropdown-label {
    height: 35px;
    margin-top: -5px;
}
.p-autocomplete-empty-message {
    padding: 15px;
    color: red;
}
/* .p-button {
    color: rgb(148, 148, 148);
    background: white;
    border: none;
} */
.autoCompleteInput {
    border: none
}
</style>