<template>
    <div class="container" v-if="singleFormData" @contextmenu="showContextMenu">
        <p class="testGroupNotice" v-if="singleFormData.testGroups">Testgrupp: <i>{{ singleFormData.testGroups[0] }}</i></p>
        <div class="health-bar">
            <div class="health-bar-segment" v-for="segment in 10" :key="segment" :style="{ background: getHealthBarSegmentColor(singleFormData.healthValuation) }"></div>
            <div class="health-bar-fill" :style="{ height: (singleFormData.healthValuation > 0 ? (100 - singleFormData.healthValuation) : 100) + '%' }"></div>
        </div>

        <div style="width: 100%">
            <div class="header" :style="{ backgroundColor: singleFormData.colorCode }">
                <div style="display: flex;">
                    <!-- <i class="pi pi-box" style="font-size: 22px; margin-top: 4px; margin-right: 7px; color: rgb(75,75,75)"></i> -->
                    <p>{{ singleFormData.product }}</p>
                </div>
                <div style="display: flex" class="dates">
                    <p style="font-size: 12px; margin-top: 0px; margin-bottom: 0;">{{ $dateLibrary.formatDate(singleFormData.created_at, 'DD MMM. YYYY (HH:mm)') }} </p>
                    <p style="font-size: 12px; margin-top: 0px; margin-bottom: 0; margin-left: 5px"> | {{singleFormData.assignee}}</p>
                    <!-- <Tag v-if="singleFormData.status == 'pending'" value="Saatmata" severity="info" style="background: dodgerblue; padding: 2px 15px 2px 15px; font-size: 14px; font-weight: 500; font-family: 'DM sans', sans-serif;" icon="pi pi-times"></Tag>
                    <Tag v-if="singleFormData.status == 'resolved'" value="Lõpetatud" severity="info" style="background: green; padding: 2px 15px 2px 15px; font-size: 14px; font-weight: 500; font-family: 'DM sans', sans-serif;" icon="pi pi-check"></Tag> -->
                    <p style="font-size: 12px; margin-top: 5px; margin-bottom: 0; text-align: end; margin-left: auto">Muudetud <b>{{ $dateLibrary.timeFromNow(singleFormData.lastChangedAt) }}</b> </p>
                </div>
                <SplitButton @click="splitButtonFunction" class="splitBtn" :label="splitBtnLabel" severity="secondary" size="small" :rounded="true" menuButtonIcon="pi pi-cog" :model="items" 
                    :pt="{
                        root: { class: 'splitBtnButton' },
                        button: { class: 'splitBtnButton' },
                        menuButton: { class: 'splitBtnButton' },
                    }"
                />
            </div>
            <!-- <div class="notice" v-if="singleFormData.healthValuation < 20">
                <i class="pi pi-exclamation-circle"></i>
                <p>Kirjuta kliendile uuesti või märgi päring läbikukkunuks</p>
            </div> -->
            <div class="underHeader">
                <div class="sector" style="width: 50%">
                    <!-- <h3><i class="pi pi-user" style="font-size: 17px;"></i> Klient</h3> -->
                    <p>{{ singleFormData.name}}</p>
                    <p><span style="color: steelblue; text-decoration: underline; cursor: pointer;" @click="copyText(singleFormData.email)">{{ singleFormData.email}}</span> <br> {{ singleFormData.phone}}</p>
                </div>
            
                <div class="sector" id="sectorFlex">
                    <!-- <h3>Tarne</h3> -->
                    <p v-if="singleFormData.shippingForm == 'assembled'">Cupella paigaldus <i @click="showAssembledInfo" class="pi pi-question-circle" style="color: steelblue; cursor: pointer"></i></p>
                    <p v-if="singleFormData.shippingForm == 'flatpak'">Klient paigaldab <i @click="showFlatpakInfo" class="pi pi-question-circle" style="color: steelblue; cursor: pointer"></i></p>
                    <OverlayPanel style="max-width: 420px" ref="overlayCompTwo">
                        <h2>Cupella läheb ja paigaldab</h2>
                        <p>Pakkumuses peab sisalduma orienteeruv kulu kliendile, nö võtmed kätte lahendus. Sh transport, paigaldus, palgad jmt.</p>
                    </OverlayPanel>
                    <OverlayPanel style="max-width: 420px" ref="overlayCompThree">
                        <h2>Klient paigaldab toote ise</h2>
                        <p>Pakkumuses peab sisalduma tarne kliendi kinnistule flatpackis. Klient on võimeline laadima kauba maha, toote paigaldama või selle ise kokku panema.</p>
                    </OverlayPanel>
                    <CountryFlag style="margin-top: 10px" :iso="singleFormData.country.code" mode="rounded" />
                    {{ singleFormData.country.name }}
                    <div v-if="singleFormData.zipCode">
                        <p>{{ singleFormData.zipCode }}</p>
                    </div>
                </div>
                <div class="sector" style="width: 100%; border: 0" v-if="singleFormData.text !== '' && singleFormData.text !== null">
                    <h3 style="color: rgb(50,50,50); font-size: 16px;font-weight: bold">Kliendi ajaline soov</h3>
                    <p>{{ singleFormData.text }}</p>
                </div>
                <div v-if="singleFormData.options.extras">
                    <div class="sector" id="clientCommentSector" style="width: 100%; text-align: start" v-if="singleFormData.options.extras.lenght > 0 || singleFormData.options.wallOptions.length > 0">
                        <h3 style="color: rgb(50,50,50); font-size: 16px;font-weight: bold">Lisadest klient on huvitatud</h3>
                        <div class="extrasSection">
                            <p v-for="element of singleFormData.options.extras" :key="element"> <i style="margin-right: 8px">|</i> {{ element }}</p>
                            <p v-for="element of singleFormData.options.wallOptions" :key="element" style="font-weight: bold"> <i style="margin-right: 8px">|</i> {{ element }}</p>
                        </div>
                    </div>
                </div>
                <div class="assigneeComment" v-if="singleFormData.extraInfo">
                    <h3>Haldaja kommentaar</h3>
                    <p v-html="singleFormData.extraInfo"></p>
                </div>
            </div>
        </div>
    </div>
    <ContextMenu ref="menu" :model="items" />
    <Dialog maximizable v-model:visible="isPopupActive" modal header="Koosta tellimus" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="header" style="margin-top: -20px;">
            <div class="sector">
                <h3><i class="pi pi-box" style="font-size: 17px;"></i> Toote nimi</h3>
                <InputText id="newProductName" v-model="newProductName" aria-describedby="newProductName-help" />
                <!-- <small id="newProductName-help">Siia mingi tekst?</small> -->
            </div>
            <p>{{ singleFormData.Ajatempel }}</p>
        </div>
        <div class="underHeader">
            <div class="sector">
                <h3><i class="pi pi-at" style="font-size: 17px;"></i> Kliendi email</h3>
                <div class="flex flex-column gap-2">
                    <InputText id="newEmail" v-model="newEmail" size="small" aria-describedby="newEmail-help" />
                    <!-- <small id="newEmail-help">Siia mingi tekst?</small> -->
                </div>
            </div>
            <div class="sector" style="text-align: end;">
                <h3><i class="pi pi-send" style="font-size: 17px;"></i> Tarneviis</h3>
                    <Dropdown v-model="newTransportMethod" :options="transportOptions" placeholder="Vali tarneviis" class="w-full md:w-12rem" />
            </div>
        </div>
        <div class="containerBody">
            <div class="sector">
                <h3 v-if="featuresList">
                    <i class="pi pi-check-square" style="font-size: 17px;"></i>
                    Toote lisad ({{ featuresList.length }}) 
                </h3>
                <h3 v-else>Lisad puuduvad</h3>
                <div class="twoColumns">
                    <Chips 
                    placeholder="Sisesta lisasid" 
                    :inputStyle="{border: '2px solid rgb(220,220,220)', borderRadius: '5px', padding: '5px 0px 5px 5px', marginTop: '5px', width: '500px'}" 
                    v-model="featuresList" 
                    separator=","  
                    :pt="{
                        root: { class: 'chip-root' },
                        container: { class: 'chip-container' },
                            token: { class: 'chip-token' }
                        }"
                    />
                    <!-- <p v-for="element of featuresList" :key="element.id">{{ element }}</p> -->
                </div>
            </div>
        </div>
        
        <span class="p-float-label">
            <Textarea v-model="notes" resize="none" rows="5" cols="30" />
            <label>Sisesta kommentaar</label>
        </span>
        
        <ButtonPrime type="button" ref="postBtn" label="Kinnita andmed" icon="pi pi-check" :loading="loading" @click="postProductionOrder(index)" />
    </Dialog>
    <Dialog maximizable v-model:visible="isInfoPopupActive" modal header="Haldaja lisainfo" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        
        <Editor v-model="extraInfo" editorStyle="height: 300px" >
            <template v-slot:toolbar>
                <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <select class="ql-color"></select>
                </span>
            </template>
        </Editor>
        <ButtonPrime type="button" ref="postBtn" label="Kinnita" icon="pi pi-check" :loading="loading" @click="patchInquiryInfo()" />
    </Dialog>
    <Toast ref="toastComp" />
</template>

<script>
import ContextMenu from 'primevue/contextmenu';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Chips from 'primevue/chips';
// import Tag from 'primevue/tag';
import CountryFlag from 'vue3-country-flag-icon'
import 'vue3-country-flag-icon/dist/CountryFlag.css'
import SplitButton from 'primevue/splitbutton';
import Editor from 'primevue/editor';
import OverlayPanel from 'primevue/overlaypanel';

export default {
    name: 'singleInquiry',
    components: {
        ContextMenu,
        Dialog,
        Textarea,
        Toast,
        InputText,
        Dropdown,
        Chips,
        Editor,
        CountryFlag,
        SplitButton,
        OverlayPanel
        // Tag
    },
    props: {
        singleFormData: {},
        index: null
    },
    computed: {
        splitBtnLabel: function() {
            switch (this.singleFormData.status) {
                case 'pending':
                    return 'Märgi saadetuks';
                case 'negotiating':
                    return 'Märgi läbirääkimisel';
                case 'negotiating_marinating':
                    return 'Märgi makstuks';
                case 'resolved_sold':
                    return 'Märgi müüduks';
                case 'finalized':
                    return 'Lisa kommentaar';
                case 'resolved_lost':
                    return 'Tagasi läbirääkimisele';
                default:
                    return null;
            }
        }
    },
    data () {
        return {
            newProductName: this.singleFormData.toode,
            newEmail: this.singleFormData['E-posti aadress'],
            newTransportMethod: this.singleFormData.Tarneviis,
            featuresList: this.singleFormData.Lisad,
            notes: null,

            transportOptions: [
                'Kokku pandud',
                'Pakis'
            ],
            isColumnHidden: true,
            items: [],
            extraInfo: null,
            isInfoPopupActive: false,
            isPopupActive: false,
            loading: false,
            value:[],
            segmentColors: [
                
                // Add colors for other segments as needed
                // Make sure to have colors for all 10 segments
            ]
        }
    },
    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text)
            this.$refs.toastComp.add({ severity: 'success', summary: 'Emaili aadress kopeeritud!', life: 3000 })
        },

        showAssembledInfo(event) {
            this.$refs.overlayCompTwo.toggle(event);
        },

        showFlatpakInfo(event) {
            this.$refs.overlayCompThree.toggle(event);
        },

        showContextMenu(event) {
            this.$refs.menu.show(event)
        },

        showProductionOrderPopup() {
            this.isPopupActive = true
        },

        toggleColumn() {
            this.isColumnHidden = !this.isColumnHidden
        },

        getHealthBarSegmentColor(valuation) {
            if (valuation >= 80) {
                return '#01BB6D';
            }
            if (valuation <= 80 && valuation > 60) {
                return '#A5DD9B';
            }
            if (valuation <= 60 && valuation > 40) {
                return '#FFF89A';
            }
            if (valuation <= 40  && valuation > 20) {
                return '#F9B572';
            } 
            if (valuation <= 20) {
                return '#FF8080';
            }
        },

        assignForm() {
            fetch(this.$fetchIp+`/api/form/${this.singleFormData.id}/assign`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success')
                    this.$refs.toastComp.add({ severity: 'success', summary: 'Päringu haldaja määratud!', life: 3000 })
                    this.$emit('updateInquiriesWithoutLoading')
            })
        },

        splitButtonFunction() {
            if(this.singleFormData.status == 'pending') {
                this.patchInquiryStatus('negotiating')
            }
            if(this.singleFormData.status == 'negotiating') {
                this.patchInquiryStatus('negotiating_marinating')
            }
            if(this.singleFormData.status == 'negotiating_marinating') {
                this.patchInquiryStatus('resolved_sold')
            }
            if(this.singleFormData.status == 'resolved_sold') {
                this.patchInquiryStatus('finalized')
            }
            if(this.singleFormData.status == 'finalized') {
                this.isInfoPopupActive = true
            }
            if(this.singleFormData.status == 'resolved_lost') {
                this.patchInquiryStatus('negotiating_marinating')
            }
        },

        patchInquiryStatus(newStatus, timeReset) {
            fetch(this.$fetchIp+`/api/form/${this.singleFormData.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "status": newStatus, 
                })
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success') {
                    if(timeReset) {
                        this.$emit('showSuccessMsg', 'Päringu eluiga pikendatud!')
                    } else {
                        this.$emit('showSuccessMsg', 'Päringu staatus muudetud!')
                    }
                    this.$emit('updateInquiriesWithoutLoading')
                }
            })
        },
        patchInquiryInfo() {
            fetch(this.$fetchIp+`/api/form/${this.singleFormData.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "extraInfo": this.extraInfo, 
                })
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success') {
                    this.$emit('updateInquiriesWithoutLoading')
                    this.isInfoPopupActive = false
                }
            })
        },

        patchInquiryColor(color) {
            fetch(this.$fetchIp+`/api/form/${this.singleFormData.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "colorCode": color, 
                })
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success') {
                    this.$emit('updateInquiriesWithoutLoading')
                }
            })
        },
    },
    mounted() {
        setTimeout(() => {
            this.extraInfo = this.singleFormData.extraInfo
            if (this.singleFormData.status == 'archived') {
                this.items = [
                    { label: 'Halda', icon: 'pi pi-folder-open', items: [
                        {
                            label: 'Taasta',
                            icon: 'pi pi-check-circle',
                            command: () => {
                                this.removeFromIgnoreList()
                            }
                        }
                    ]}
                ]
            } else {
                this.items = [
                    { label: 'Staatus', icon: 'pi pi-folder-open', items: [
                        {
                            label: 'Saatmata',
                            command: () => {
                                this.patchInquiryStatus('pending')
                            }
                        },
                        {
                            label: 'Saadetud',
                            command: () => {
                                this.patchInquiryStatus('negotiating')
                            }
                        },
                        {
                            label: 'Läbirääkimisel',
                            command: () => {
                                this.patchInquiryStatus('negotiating_marinating')
                            }
                        },
                        {
                            label: 'Ettemaks tehtud',
                            command: () => {
                                this.patchInquiryStatus('resolved_sold')
                            }
                        },
                        {
                            label: 'Müüdud',
                            command: () => {
                                this.patchInquiryStatus('finalized')
                            }
                        },
                        {
                            label: 'Läbikukkunud',
                            command: () => {
                                this.patchInquiryStatus('resolved_lost')
                            }
                        }
                    ] },
                    { label: 'Pikenda aega', icon: 'pi pi-history',command: () => {
                            this.patchInquiryStatus(this.singleFormData.status, true)
                        }
                    },
                    { label: 'Kommenteeri', icon: 'pi pi-pencil',command: () => {
                            this.isInfoPopupActive = true
                            setTimeout(() => {
                                this.extraInfo = this.singleFormData.extraInfo
                            },100)
                        }
                    },
                    { label: 'Võta haldus üle', icon: 'pi pi-check-circle',command: () => {
                            this.assignForm()
                        }
                    },
                    { label: 'Määra värv', icon: 'pi pi-bookmark-fill', items: [
                        {
                            label: 'Valge',
                            command: () => {
                                this.patchInquiryColor('white')
                            }
                        },
                        {
                            label: 'Punane',
                            command: () => {
                                this.patchInquiryColor('#FEA1A1')
                            }
                        },
                        {
                            label: 'Kollane',
                            command: () => {
                                this.patchInquiryColor('#FFFAB7')
                            }
                        },
                        {
                            label: 'Oranž',
                            command: () => {
                                this.patchInquiryColor('#FFBB70')
                            }
                        },
                        {
                            label: 'Sinine',
                            command: () => {
                                this.patchInquiryColor('#B9E9FC')
                            }
                        }
                    ] },
                ]
            }
        }, 200)
    }
}
</script>

<style scoped>
.notice {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #FDD998;
    padding-left: 15px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #975b00;
}
.notice p {
    margin: 0;
    margin-top: 0px;
    margin-left: 10px;
}
.notice i {
    margin-top: 0px;
    font-size: 24px;
    color: #FF9800;
}
.health-bar {
    position: absolute;
    left: 0;
    width: 8px; 
    height: 100%;
}
.health-bar-segment {
    width: 100%;
    height: 10%;
}
.health-bar-fill {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(216, 224, 230); /* initial background color */
}
h3 {
    font-family: "Quicksand", sans-serif;
}
.testGroupNotice {
    position: absolute;
    bottom: -3px;
    right: 15px;
    font-size: 12px;
    color: silver;
}
.container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 687px;
    overflow: hidden;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: rgb(255, 255, 255);
    padding-bottom: 10px;
    padding-left: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 15px;
    margin-bottom: 15px;
}
.header {
    position: relative;
    padding-top: 10px;
    padding-bottom: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    border-bottom: 1px solid rgb(230,230,230);
    padding-left: 15px;
    padding-right: 15px;
}
.header p {
    font-size: 20px;
    margin: 0;
    color: rgb(50,50,50);
}
.dates p:first-child {
    color: rgb(125,125,125);
}
.dates p:nth-child(2) {
    color: teal;
}
.dates p:nth-child(3) {
    color: rgb(125,125,125);
}
.assigneeText {
    position: absolute;
    right: 15px;
    bottom: 0;
    font-size: 12px;
}
.splitBtn {
    position: absolute;
    top: 12px;
    right: 15px;
}
.splitBtnButton {
    padding: 0;
    margin: 0;
}
.underHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
    /* border-bottom: 1px solid rgb(240,240,240); */
}
.underHeader p {
    font-size: 14px;
}
.underHeader .sector:nth-child(2) {
    text-align: end;
}
.underHeader .sector:nth-child(3) {
    text-align: start;
}
.underHeader .sector:nth-child(4) {
    text-align: end;
}
.underHeader .sector {
    width: 50%;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(230,230,230);
}
.underHeader .sector:last-child {
    margin-bottom: 0;
    border: 0;
}
.extrasSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.extrasSection p {
    padding-right: 10px;
}
.containerBody {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 15px;
}
.containerBody .sector {
    width: 100%;
}
.twoColumns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    /* -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.twoColumns div {
    width: 25%;
    text-align: start;
    margin-bottom: 10px;
}
.twoColumns h3 {
    font-size: 16px;
    text-align: start;
    width: 100%;
    margin-top: 5px;
}
.twoColumns p {
    font-size: 12px;
    width: 100%;
    border-bottom: 1px dashed rgb(220,220,220);
    padding-bottom: 8px;
    padding-top: 6px;
}
.twoColumnsHidden {
    overflow: hidden;
    height: 0;
}
.assigneeComment {
    background: rgb(235, 235, 235);
    border-radius: 10px;
    width: 100%;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 15px;
    margin-left: auto;
    text-align: end;
}
.assigneeComment p {
    margin: 0;
    color: rgb(50,50,50)
}
.assigneeComment h3 {
    margin: 0;
    margin-bottom: 5px;
    color: rgb(16, 172, 146)
}
.sector p {
    margin: 0;
    color: rgb(125,125,125);
}
.sector h3 {
    margin: 0;
    margin-bottom: 5px;
    color: rgb(0,0,0);
    font-weight: 500;
}
.containerBody h3:hover {
    cursor: pointer;
    color: steelblue;
}
.p-button {
    margin-top: 20px;
    background: #4ece96;
    color: white;
}
.p-float-label {
    margin-top: 25px;
}

@media(max-width: 1690px) {
    /* .container {
        width: 49%;
    } */

}
@media(max-width: 1560px) {
    /* .container {
        width: 48%;
    }
    .underHeader .sector:nth-child(1) {
        width: 44%;
    }
    .underHeader .sector {
        width: 26%;
    } */
}
@media(max-width: 450px) {
    /* #sectorFlex {
        width: 50%;
        text-align: start;
    } */
}
</style>
<style>
.chip-token {
    margin-top: 5px;
    margin-bottom: 5px;
}
.chip-root:focus {
    -webkit-box-shadow: none;
            box-shadow: none
}
.chip-container {
    border: none;
    padding: 0;
}
.chip-container:focus {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none
}
.p-chips:not(.p-disabled):hover .p-chips-multiple-container {
    border-color: #2195f300;
}
.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #a6d6fa00;
            box-shadow: 0 0 0 0.2rem #a6d6fa00;
    border-color: #2195f300;
}
</style>