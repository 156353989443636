<template>
    <div class="container" v-if="singleFormData">
        <p class="testGroupNotice" v-if="singleFormData.testGroups">Testgrupp: <i>{{ singleFormData.testGroups[0] }}</i></p>
        <div class="health-bar">
            <div class="health-bar-segment" v-for="segment in 10" :key="segment" :style="{ background: getHealthBarSegmentColor(singleFormData.healthValuation) }"></div>
            <!-- <div class="health-bar-fill" :style="{ height: (100 - singleFormData.healthValuation) + '%' }"></div> -->
            <div class="health-bar-fill" :style="{ height: (singleFormData.healthValuation > 0 ? (100 - singleFormData.healthValuation) : 100) + '%' }"></div>
        </div>
        <!-- <div class="header" :style="{ backgroundColor: bgColor }"> -->
        <div class="header" :style="{ background: index % 3 === 1 ? 'white' : 'rgb(235,235,235)' }">
            <div style="display: flex;">
                <!-- <i class="pi pi-box" style="font-size: 22px; margin-top: 4px; margin-right: 7px; color: white"></i> -->
                <p>{{ singleFormData.product }}</p>
            </div>
            <div >
                <p style="font-size: 12px; margin-top: 0px; margin-bottom: 0; color: rgb(125,125,125)">{{ $dateLibrary.formatDate(singleFormData.created_at, 'DD MMM. YYYY (HH:mm)') }}</p>
                <!-- <Tag v-if="singleFormData.status == 'new'" value="Ootab vastust" severity="info" style="background: gray; padding: 2px 15px 2px 15px; font-size: 14px; font-weight: 500; font-family: 'DM sans', sans-serif;" icon="pi pi-check"></Tag>
                <Tag v-if="singleFormData.status == 'pending'" value="Töösse võetud" severity="info" style="background: dodgerblue; padding: 2px 15px 2px 15px; font-size: 14px; font-weight: 500; font-family: 'DM sans', sans-serif;" icon="pi pi-check"></Tag>
                <Tag v-if="singleFormData.status == 'resolved'" value="Lõpetatud" severity="info" style="background: green; padding: 2px 15px 2px 15px; font-size: 14px; font-weight: 500; font-family: 'DM sans', sans-serif;" icon="pi pi-check"></Tag> -->
            </div>
            <SplitButton @click="assignForm" class="splitBtn" label="Halda" severity="secondary" size="small" :rounded="true" menuButtonIcon="pi pi-cog" :model="btnItems" 
                :pt="{
                    root: { class: 'splitBtnButton' },
                    button: { class: 'splitBtnButton' },
                    menuButton: { class: 'splitBtnButton' },
                }"
            />
        </div>
        <div class="underHeader">
            <div class="sector" style="width: 50%" :style="{ borderBottom: singleFormData.text === '' || singleFormData.text === null ? '0' : '1px solid rgb(230,230,230)' }">
                <!-- <h3><i class="pi pi-user" style="font-size: 17px;"></i> Klient</h3> -->
                <p>{{ singleFormData.name}}</p>
                <p><span style="color: steelblue; text-decoration: underline; cursor: pointer;" @click="copyText(singleFormData.email)">{{ singleFormData.email}}</span> <br> {{ singleFormData.phone}}</p>
            </div>
            
            <div class="sector" :style="{ borderBottom: singleFormData.text === '' || singleFormData.text === null ? '0' : '1px solid rgb(230,230,230)' }">
                <div id="sectorFlex" style="margin-bottom: 10px">
                    <!-- <h3>Tarneviis</h3> -->
                    <p v-if="singleFormData.shippingForm == 'assembled'">Cupella paigaldus <i @click="showAssembledInfo" class="pi pi-question-circle" style="color: steelblue; cursor: pointer"></i></p>
                    <p v-if="singleFormData.shippingForm == 'flatpak'">Klient paigaldab <i @click="showFlatpakInfo" class="pi pi-question-circle" style="color: steelblue; cursor: pointer"></i></p>
                    <OverlayPanel style="max-width: 420px" ref="overlayCompTwo">
                        <h2>Cupella läheb ja paigaldab</h2>
                        <p>Pakkumuses peab sisalduma orienteeruv kulu kliendile, nö võtmed kätte lahendus. Sh transport, paigaldus, palgad jmt.</p>
                    </OverlayPanel>
                    <OverlayPanel style="max-width: 420px" ref="overlayCompThree">
                        <h2>Klient paigaldab toote ise</h2>
                        <p>Pakkumuses peab sisalduma tarne kliendi kinnistule flatpackis. Klient on võimeline laadima kauba maha, toote paigaldama või selle ise kokku panema.</p>
                    </OverlayPanel>
                </div>
                <div>
                    <CountryFlag :iso="singleFormData.country.code" mode="rounded" />
                    {{singleFormData.country.name}}
                </div>
                <div v-if="singleFormData.zipCode">
                    <p>{{ singleFormData.zipCode }}</p>
                </div>
            </div>
            <div class="sector" id="clientCommentSector" style="width: 100%;" v-if="singleFormData.text !== '' && singleFormData.text !== null">
                <h3 style="color: rgb(50,50,50); font-size: 16px; font-weight: bold;">Kliendi ajaline soov</h3>
                <p>{{ singleFormData.text }}</p>
            </div>
            <div v-if="singleFormData.options.extras">
                <div class="sector" id="clientCommentSector" style="width: 100%; text-align: start" v-if="singleFormData.options.extras.lenght > 0 || singleFormData.options.wallOptions.length > 0">
                    <h3 style="color: rgb(50,50,50); font-size: 16px;font-weight: bold">Lisadest klient on huvitatud</h3>
                    <div class="extrasSection">
                        <p v-for="element of singleFormData.options.extras" :key="element"> <i style="margin-right: 8px">|</i> {{ element }}</p>
                        <p v-for="element of singleFormData.options.wallOptions" :key="element" style="font-weight: bold"> <i style="margin-right: 8px">|</i> {{ element }}</p>
                    </div>
                </div>
            </div>
            <div class="assigneeComment" v-if="singleFormData.extraInfo">
                <h3>Haldaja kommentaar</h3>
                <p v-html="singleFormData.extraInfo"></p>
            </div>
        </div>
    </div>
    <ContextMenu ref="menu" :model="items" />
    <Dialog maximizable v-model:visible="isPopupActive" modal header="Koosta tellimus" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="header" style="margin-top: -20px;">
            <div class="sector">
                <h3><i class="pi pi-box" style="font-size: 17px;"></i> Toote nimi</h3>
                <InputText id="newProductName" v-model="newProductName" aria-describedby="newProductName-help" />
                <!-- <small id="newProductName-help">Siia mingi tekst?</small> -->
            </div>
            <p>{{ singleFormData.Ajatempel }}</p>
        </div>
        <div class="underHeader">
            <div class="sector">
                <h3><i class="pi pi-at" style="font-size: 17px;"></i> Kliendi email</h3>
                <div class="flex flex-column gap-2">
                    <InputText id="newEmail" v-model="newEmail" size="small" aria-describedby="newEmail-help" />
                    <!-- <small id="newEmail-help">Siia mingi tekst?</small> -->
                </div>
            </div>
            <div class="sector" style="text-align: end;">
                <h3><i class="pi pi-send" style="font-size: 17px;"></i> Tarneviis</h3>
                    <Dropdown v-model="newTransportMethod" :options="transportOptions" placeholder="Vali tarneviis" class="w-full md:w-12rem" />
            </div>
        </div>
        <div class="containerBody">
            <div class="sector">
                <h3 v-if="featuresList">
                    <i class="pi pi-check-square" style="font-size: 17px;"></i>
                    Toote lisad ({{ featuresList.length }}) 
                </h3>
                <h3 v-else>Lisad puuduvad</h3>
                <div class="twoColumns">
                    <Chips 
                        placeholder="Sisesta lisasid" 
                        :inputStyle="{border: '2px solid rgb(220,220,220)', borderRadius: '5px', padding: '5px 0px 5px 5px', marginTop: '5px', width: '500px'}" 
                        v-model="featuresList" 
                        separator=","  
                        :pt="{
                            root: { class: 'chip-root' },
                            container: { class: 'chip-container' },
                            token: { class: 'chip-token' }
                        }"
                    />
                    <!-- <p v-for="element of featuresList" :key="element.id">{{ element }}</p> -->
                </div>
            </div>
        </div>

        <span class="p-float-label">
            <Textarea v-model="notes" resize="none" rows="5" cols="30" />
            <label>Sisesta kommentaar</label>
        </span>

        <ButtonPrime type="button" ref="postBtn" label="Kinnita andmed" icon="pi pi-check" :loading="loading" @click="postProductionOrder(index)" />
    </Dialog>
    <Dialog maximizable v-model:visible="isInfoPopupActive" modal header="Haldaja lisainfo" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

        <Editor v-model="extraInfo" editorStyle="height: 300px" >
            <template v-slot:toolbar>
                <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <select class="ql-color"></select>
                </span>
            </template>
        </Editor>

        <ButtonPrime type="button" ref="postBtn" label="Kinnita" icon="pi pi-check" :loading="loading" @click="patchInquiryInfo()" />
    </Dialog>
    <Toast ref="toastComp" />
</template>

<script>
import ContextMenu from 'primevue/contextmenu';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Chips from 'primevue/chips';
import CountryFlag from 'vue3-country-flag-icon'
  import 'vue3-country-flag-icon/dist/CountryFlag.css'
// import Tag from 'primevue/tag';
import SplitButton from 'primevue/splitbutton';
import Editor from 'primevue/editor';
import OverlayPanel from 'primevue/overlaypanel';

export default {
    name: 'singleInquiry',
    components: {
        ContextMenu,
        Dialog,
        Textarea,
        Toast,
        InputText,
        Dropdown,
        Chips,
        CountryFlag,
        SplitButton,
        Editor,
        OverlayPanel
        // Tag
    },
    props: {
        singleFormData: {},
        index: null
    },
    data () {
        return {
            newProductName: this.singleFormData.toode,
            newEmail: this.singleFormData['E-posti aadress'],
            newTransportMethod: this.singleFormData.Tarneviis,
            featuresList: this.singleFormData.Lisad,
            notes: null,

            transportOptions: [
                'Kokku pandud',
                'Pakis'
            ],

            isColumnHidden: true,
            items: [],
            extraInfo: null,
            isInfoPopupActive: false,
            isPopupActive: false,
            loading: false,
            value:[],
            btnItems:[
                {
                    label: 'Haldajaks',
                    icon: 'pi pi-check',
                    command: () => {
                        this.assignForm()
                    }
                },
                {
                    label: 'Kommenteeri',
                    icon: 'pi pi-comment',
                    command: () => {
                        this.isInfoPopupActive = true
                    }
                },
                {
                    label: 'Kustuta päring',
                    icon: 'pi pi-times',
                    command: () => {
                        this.patchInquiryStatus('discarded')
                    }
                },
            ]

        }
    },
    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text)
            this.$refs.toastComp.add({ severity: 'success', summary: 'Emaili aadress kopeeritud!', life: 3000 })
        },

        showContextMenu(event) {
            this.$refs.menu.show(event)
        },

        showProductionOrderPopup() {
            this.isPopupActive = true
        },

        toggleColumn() {
            this.isColumnHidden = !this.isColumnHidden
        },

        showAssembledInfo(event) {
            this.$refs.overlayCompTwo.toggle(event);
        },

        showFlatpakInfo(event) {
            this.$refs.overlayCompThree.toggle(event);
        },

        getHealthBarSegmentColor(valuation) {
            if (valuation >= 80) {
                return '#01BB6D';
            }
            if (valuation <= 80 && valuation > 60) {
                return '#A5DD9B';
            }
            if (valuation <= 60 && valuation > 40) {
                return '#FFF89A';
            }
            if (valuation <= 40  && valuation > 20) {
                return '#F9B572';
            } 
            if (valuation <= 20) {
                return '#FF8080';
            }
        },

        assignForm() {
            fetch(this.$fetchIp+`/api/form/${this.singleFormData.id}/assign`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success')
                    this.patchInquiryStatus('pending')
            })
        },

        patchInquiryStatus(newStatus) {
            fetch(this.$fetchIp+`/api/form/${this.singleFormData.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "status": newStatus, 
                })
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success')
                    this.$emit('updateInquiriesWithoutLoading')
                    if(newStatus == 'discarded') {
                        this.$emit('showSuccessMsg', 'Päring kustatud!')
                    } else {
                        this.$emit('showSuccessMsg', 'Päringu haldaja määratud!')
                    }
            })
        },
        patchInquiryInfo() {
            fetch(this.$fetchIp+`/api/form/${this.singleFormData.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "extraInfo": this.extraInfo, 
                })
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success') {
                    this.$refs.toastComp.add({ severity: 'success', summary: 'Haldaja kommentaar muudetud!', life: 3000 })
                    this.$emit('updateInquiriesWithoutLoading')
                    this.isInfoPopupActive = false
                }
            })
        },

        postProductionOrder(index) {
            this.loading = true
            fetch(this.$fetchIp+`/api/production-order`, {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "productTitle": this.newProductName, 
                    "email": this.newEmail,
                    "notes": this.notes,
                    "timestamp": this.singleFormData.Ajatempel,
                    "attr": {
                        "shippingMethod": this.newTransportMethod,
                        "features": this.featuresList
                    },
                })
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success') {
                    setTimeout(() => {
                        this.loading = false
                        this.isPopupActive = false
                        this.$emit('productionOrderPosted', true, index)
                    }, 2000);
                }
            })
        },

        addToIgnoreList() {
            fetch(this.$fetchIp+`/api/form?formTitle=${this.singleFormData.toode}&timestamp=${this.singleFormData.Ajatempel}&action=ignore`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success') {
                    this.$emit('productionOrderPosted', true, this.index)
                    this.$refs.toastComp.add({ severity: 'success', summary: 'Päring edukalt arhiveeritud!', life: 3000 })

                }
            })
        },

        removeFromIgnoreList() {
            fetch(this.$fetchIp+`/api/form?formTitle=${this.singleFormData.toode}&timestamp=${this.singleFormData.Ajatempel}&action=restore`, {
                method: 'PATCH',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res.status == 'success') {
                    this.$emit('productionOrderPosted', true, this.index)
                    this.$refs.toastComp.add({ severity: 'success', summary: 'Päring taastatud!', life: 3000 })
                }
            })
        }
    },
    mounted() {
        setTimeout(() => {
            this.extraInfo = this.singleFormData.extraInfo
            if (this.singleFormData.status == 'archived') {
                this.items = [
                    { label: 'Halda', icon: 'pi pi-folder-open', items: [
                        {
                            label: 'Taasta',
                            icon: 'pi pi-check-circle',
                            command: () => {
                                this.removeFromIgnoreList()
                            }
                        }
                    ]}
                ]
            } else {
                this.items = [
                    { label: 'Määra haldajaks', icon: 'pi pi-check-circle',
                        command: () => {
                            this.assignForm()
                        }
                    },
                    { label: 'Muuda staatust', icon: 'pi pi-folder-open', items: [
                        {
                            label: 'Vastamata',
                            command: () => {
                                this.patchInquiryStatus('new')
                            }
                        },
                        {
                            label: 'Vastatud',
                            command: () => {
                                this.patchInquiryStatus('pending')
                            }
                        },
                        {
                            label: 'Lõpetatud',
                            command: () => {
                                this.patchInquiryStatus('resolved')
                            }
                        }
                    ] },
                    { label: 'Lisa kommentaar', icon: 'pi pi-pencil',command: () => {
                            this.isInfoPopupActive = true
                        }
                    },
                ]
            }
        }, 200)
    }
}
</script>

<style>
.p-splitbutton {
    border: none;
    outline: none;
}
.p-splitbutton-defaultbutton {
    padding: 0;
    padding-right: 5px;
    padding-left: 5px;
    background: rgb(1, 187, 109);
    border: none;
}
.p-splitbutton-menubutton{
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    width: 35px;
    background: rgb(2, 179, 105);
    outline: none;
    border: none;
    border-left: 1px solid rgb(46, 194, 132);
}
</style>
<style scoped>
.health-bar {
    position: absolute;
    left: 0;
    width: 8px;
    height: 100%;
}
.health-bar-segment {
    width: 100%;
    height: 10%;
}
.health-bar-fill {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(216, 224, 230);
}
.splitBtn {
    position: absolute;
    top: 17px;
    right: 15px;
}
.splitBtnButton {
    padding: 0;
    margin: 0;
}
.testGroupNotice {
    position: absolute;
    bottom: -5px;
    right: 15px;
    font-size: 12px;
    color: silver;
}
.container {
    position: relative;
    width: 450px;
    overflow: hidden;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: rgb(255, 255, 255);
    padding-bottom: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 15px;
    margin-bottom: 25px;
    padding-left: 8px;
}
.header {
    position: relative;
    padding-top: 10px;
    padding-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    border-bottom: 1px solid rgb(230,230,230);
    padding-left: 15px;
    padding-right: 15px;
}
.header p {
    font-size: 20px;
    margin: 0;
    color: rgb(50, 50, 50);
    padding-right: 100px;
    white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}
.underHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
}
.underHeader p {
    font-size: 14px;
}
.underHeader .sector:nth-child(2) {
    text-align: end;
}
.underHeader .sector:nth-child(4) {
    text-align: end;
}
.underHeader .sector:nth-child(5) {
    text-align: center;
}
.underHeader .sector:nth-child(6) {
    text-align: end;
}

.underHeader .sector:nth-child(8) {
    text-align: end;
}
.underHeader .sector {
    width: 50%;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(230,230,230);
}
#clientCommentSector {
    border: none;
}
.extrasSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.extrasSection p {
    padding-right: 10px;
}
.assigneeComment {
    background: rgb(235, 235, 235);
    border-radius: 10px;
    width: 100%;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 15px;
    margin-left: auto;
    text-align: end;
}
.assigneeComment p {
    margin: 0;
    color: rgb(50,50,50)
}
.assigneeComment h3 {
    margin: 0;
    margin-bottom: 5px;
    color: rgb(16, 172, 146);
    font-weight: 600;
}
h3 {
    font-family: "Quicksand", sans-serif;
}
.containerBody {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 15px;
}
.containerBody .sector {
    width: 100%;
}
.twoColumns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    /* -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.twoColumns div {
    width: 25%;
    text-align: start;
    margin-bottom: 10px;
}
.twoColumns h3 {
    font-size: 16px;
    text-align: start;
    width: 100%;
    margin-top: 5px;
}
.twoColumns p {
    font-size: 12px;
    width: 100%;
    border-bottom: 1px dashed rgb(220,220,220);
    padding-bottom: 8px;
    padding-top: 6px;
}
.twoColumnsHidden {
    overflow: hidden;
    height: 0;
}
.sector p {
    margin: 0;
    color: rgb(125,125,125);
}
.sector h3 {
    margin: 0;
    margin-bottom: 5px;
    color: rgb(0,0,0);
    font-weight: 500;
}
.containerBody h3:hover {
    cursor: pointer;
    color: steelblue;
}
.p-button {
    margin-top: 20px;
    background: #4ece96;
    color: white;
}
.p-float-label {
    margin-top: 25px;
}

@media(max-width: 1690px) {
    /* .container {
        width: 49%;
    } */

}
@media(max-width: 1560px) {
    /* .container {
        width: 48%;
    }
    .underHeader .sector:nth-child(1) {
        width: 44%;
    }
    .underHeader .sector {
        width: 26%;
    } */
}
@media(max-width: 450px) {
    #sectorFlex {
        width: 50%;
        text-align: start;
    }
}
</style>
<style>
.chip-token {
    margin-top: 5px;
    margin-bottom: 5px;
}
.chip-root:focus {
    -webkit-box-shadow: none;
            box-shadow: none
}
.chip-container {
    border: none;
    padding: 0;
}
.chip-container:focus {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none
}
.p-chips:not(.p-disabled):hover .p-chips-multiple-container {
    border-color: #2195f300;
}
.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #a6d6fa00;
            box-shadow: 0 0 0 0.2rem #a6d6fa00;
    border-color: #2195f300;
}
</style>