<template>
    <div class="componentBody">
        <!-- <button style="position: absolute; right: 0" @click="generateInquiry">Genereeri päring</button> -->
        <sideMenuInquiry @changeTab="changePage" />
        <div class="contentBody">
            <div class="singleContent" v-if="activePage == 'inquiries'">
                <div class="" style="display: flex">
                    <h2 :style="{ fontSize: viewMode === 'isolated' ? '26px' : '16px', color: viewMode === 'isolated' ? 'black' : 'silver' }" style="cursor: pointer" @click="viewMode = 'isolated'; getInquiriesByStatus(activeTab)">Minu päringud</h2>
                    <h2 style="margin-left: 5px; margin-right: 5px">/</h2>
                    <h2 :style="{ fontSize: viewMode === 'overall' ? '26px' : '16px', color: viewMode === 'overall' ? 'black' : 'silver' }" style="cursor: pointer" @click="viewMode = 'overall'; getInquiriesByStatus(activeTab)">Kõik päringud</h2>
                </div>
                
                <div class="tabs">
                    <p @click="getInquiriesByStatus('new')" :class="{ 'tabActive': activeTab == 'new' }" >Uued ({{ inquiriesMetadata.new }})</p>
                    <p @click="getInquiriesByStatus('pending')" :class="{ 'tabActive': activeTab == 'pending' }" >Saatmata ({{ inquiriesMetadata.pending }})</p>
                    <p @click="getInquiriesByStatus('negotiating')" :class="{ 'tabActive': activeTab == 'negotiating' }">Saadetud ({{ inquiriesMetadata.negotiating }})</p>
                    <p @click="getInquiriesByStatus('negotiating_marinating')" :class="{ 'tabActive': activeTab == 'negotiating_marinating' }">Läbirääkimisel ({{ inquiriesMetadata.negotiating_marinating }})</p>
                    <p @click="getInquiriesByStatus('resolved_sold')" :class="{ 'tabActive': activeTab == 'resolved_sold' }">Ettemaks sooritatud ({{ inquiriesMetadata.resolved_sold }})</p>
                    <p @click="getInquiriesByStatus('finalized')" :class="{ 'tabActive': activeTab == 'finalized' }">Müüdud ({{ inquiriesMetadata.finalized }})</p>
                    <p @click="getInquiriesByStatus('resolved_lost')" :class="{ 'tabActive': activeTab == 'resolved_lost' }">Läbikukkunud ({{ inquiriesMetadata.resolved_lost }})</p>
                </div>

                <div class="overviewPage" v-if="activeTab == 'new' && !loading">
                    <div class="overviewHeader">
                        <h2 style="color: steelblue" v-if="unassignedInquiries">Uued jaotamata päringud ({{ unassignedInquiries.length }})</h2>
                    </div>
                    <div class="pageSplit">
                        <!-- <div class="overview">
                            <overviewComp  />
                        </div> -->
                        <div class="unassignedInquiries">
                            <newInquiry v-for="(element, index) of unassignedInquiries" :key="element.id" :singleFormData="element" :index="index" @updateInquiries="getInquiriesByStatus('new')" @updateInquiriesWithoutLoading="getInquiriesByStatus('new', true)" @showSuccessMsg="showSuccessMsg" />
                        </div>
                        <h3 style="color: silver; font-size: 30px; text-align: start; margin-top: 150px;" v-if="!unassignedInquiries && activeTab == 'new'">Siin kaustas rohkem päringuid ei paista olevat!</h3>
                    </div>
                </div>

                <ProgressSpinner style="margin-left: 45%;margin-top: 100px; width: 125px; height: 125px; color: steelblue" strokeWidth="2"  v-if="loading" />

                <div v-if="activeTab != 'new' && !loading">
                    <h2 style="color: steelblue" v-if="activeTabTitle">{{ activeTabTitle }} ({{ inquiriesMetadata[activeTab] }})</h2>
                    <div class="productsGrid">
                        <singleInquiry v-for="(element, index) of activeInquiries" :key="element.id" :singleFormData="element" :index="index" @updateInquiries="getInquiriesByStatus(activeTab)" @updateInquiriesWithoutLoading="getInquiriesByStatus(activeTab, true)" @showSuccessMsg="showSuccessMsg" />
                    </div>
                    <h3 style="color: silver; font-size: 30px; text-align: center;" v-if="!activeInquiries && activeTab != 'new'">Siin kaustas rohkem päringuid ei paista olevat!</h3>
                    <!-- <div class="productsGrid" v-else>
                        <singleProductionOrder v-for="element of allInquiries" :key="element.id" :productionOrderData="element" @getProductionOrders="getProductionOrders" @successMessage="successMessage" @editMessageSuccess="editMessageSuccess"/>
                    </div> -->
                </div>
                
            </div>
            <div class="singleContent" v-if="activePage == 'productForms'">
                <h2>Tooted</h2>

                <div class="productsGrid" v-show="!isProductLoading">
                    <singleProduct v-for="element of activeProducts" :key="element.id" :singleProductData="element"/>
                </div>
                <div class="productsGrid" v-show="isProductLoading">
                    <Skeleton width="318px" height="300px" v-for="index in 19" :key="index"></Skeleton>
                </div>
            </div>
            <div class="singleContent" v-if="activePage == 'singleListingPage'">
                <h2>Toote kuulutus</h2>

                <singleListingPage />
            </div>
            <div class="singleContent" v-if="activePage == 'salesStats'">
                <h2>Müügistatistika</h2>

                <salesStats />
            </div>

        </div>
    </div>
    <Toast ref="toastComp" />
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import singleInquiry from '@/components/inquiry/singleInquiry.vue'
import newInquiry from '@/components/inquiry/newInquiry.vue'
// import overviewComp from '@/components/inquiry/overview.vue'
// import singleProductionOrder from '@/components/inquiry/singleProductionOrder.vue'
import singleProduct from '@/components/inquiry/singleProduct.vue'
import singleListingPage from '@/components/inquiry/singleListingPage.vue'
import salesStats from '@/views/salesStats.vue'
import sideMenuInquiry from '../components/menu/sideMenuInquiry.vue'
import Toast from 'primevue/toast';
import Skeleton from 'primevue/skeleton';

export default {
    name: 'inquiryView',
    components: {
        singleInquiry,
        newInquiry,
        salesStats,
        // overviewComp,
        // singleProductionOrder,
        singleProduct,
        singleListingPage,
        Toast,
        ProgressSpinner,
        sideMenuInquiry,
        Skeleton
    },
    watch: {
        activeTab(newValue) {
            if(newValue == 'new') {
                this.activeTabTitle = null
            }
            if(newValue == 'pending') {
                this.activeTabTitle = 'Hinnapakkumine saatmata'
            }
            if(newValue == 'negotiating') {
                this.activeTabTitle = 'Hinnapakkumine saadetud'
            }
            if(newValue == 'negotiating_marinating') {
                this.activeTabTitle = 'Kliendiga läbirääkimised'
            }
            if(newValue == 'resolved_sold') {
                this.activeTabTitle = 'Klient on sooritanud ettemakse'
            }
            if(newValue == 'finalized') {
                this.activeTabTitle = 'Edukalt sooritatud müügid'
            }
            if(newValue == 'resolved_lost') {
                this.activeTabTitle = 'Läbikukkunud müügid'
            }
            if(newValue == 'discarded') {
                this.activeTabTitle = 'Kustutatud päringud'
            }
        },
        viewMode(newMode) {
            if(newMode == 'overall') {
                this.fontSize = '26px'
            }
        }
    },
    props: {
        displayName: {
        
        }
    },
    data () {
        return {
            activeTabTitle: null,
            viewMode: 'isolated',
            fontSize: '16px',
            isProductLoading: false,
            loading: false,
            activePage: 'inquiries',
            activeTab: 'new',
            activeInquiries: null,
            productsET: null,
            productsEN: null,
            activeProducts: null,
            allInquiries: [],
            clientInquiries: [],
            productionOrders: null,
            organizedOrders: {
                'new': [],
                'pending': [],
                'resolved': [],
                'assignedToMe': []
            },
            inquiriesMetadata: {
                'new': 0,
                'pending': 0,
                'pending_sour': 0,
                'negotiating': 0,
                'negotiating_marinating': 0,
                'negotiating_sour': 0,
                'resolved': 0,
                'resolved_sold': 0,
                'resolved_lost': 0,
                'finalized': 0,
            },
            unassignedInquiries: null
        }
    },
    methods: {
        changePage(newPage) {
            if(newPage == 'productForms') {
                this.isProductLoading = true
                setTimeout(() => {
                    this.isProductLoading = false
                }, 2000)
            }
            this.activePage = newPage
        },

        changeTab(newTab) {
            if(newTab == 'archived') {
                this.activeTab = newTab
            } else {
                this.allInquiries = this.organizedOrders[newTab]
                this.activeTab = newTab
            }
        },

        showSuccessMsg(msg) {
            this.$refs.toastComp.add({ severity: 'success', summary: msg, life: 5000 })
        },

        successMessage() {
            this.$refs.toastComp.add({ severity: 'success', summary: 'Tootmiskäsk loodud!', life: 3000 })
        },
        editMessageSuccess() {
            this.$refs.toastComp.add({ severity: 'success', summary: 'Andmed edukalt muudetud!', life: 3000 })
        },

        generateInquiry() {
            let sendBody
            
            sendBody = {
                "product": 'spaMini',
                "name": 'Madis Tamm',
                "email": 'madis.tamm@gmail.com',
                "country": {
                    "name": "Lithuania",
                    "code": "LT"
                },
                "zipCode": "86501",
                "phone": '+372 5545403',
                "text": 'By end of july ',
                "isShippingRequired": true,
                "shippingForm": 'flatpak',
                "isPaymentByInstallations": true,
                "options": {
                    "wallOptions": ['Tagasein-Täispuit'],
                    "extras": []
                },
                "testGroups": ['withOptions-A'],
                "lang": 'et'
            }
            
            fetch(`http://localhost:6063/api/form`, {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'omit',
                body: JSON.stringify(sendBody)
            })
            .then((res) => res.json())
            .then((res) =>  {
                console.log(res)
            })
        },

        getNewInquiries: async function(status) {
            this.loading = true
            this.activeTab = status
            fetch(this.$fetchIp+`/api/form?status=new&assignee=`, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res) {
                    this.unassignedInquiries = res.data
                    this.getInquiriesMetadata()
                }
            })
        },

        getInquiriesByStatus(status, withoutLoading) {
            if(withoutLoading) {
                this.loading = false
            } else {
                this.loading = true
            }
            var param
            if(this.viewMode == 'isolated' && status != 'new') {
                param = `/api/form?status=${status}&assignee=${this.displayName}`
            } else {
                param = `/api/form?status=${status}`
            }
            fetch(this.$fetchIp+param, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res) {
                    if(status == 'new') {
                        this.unassignedInquiries = res.data
                        if(this.unassignedInquiries) {
                            this.unassignedInquiries = this.calculateValuation(this.unassignedInquiries, "created_at", 48)
                        }
                        
                    } else {
                        this.activeInquiries = res.data

                        let timestampKey
                        let maxTimePassed
                        if(status == 'pending') {
                            timestampKey = 'pending_at'
                            maxTimePassed = 48
                        }
                        if(status == 'negotiating') {
                            timestampKey = 'negotiating_at'
                            maxTimePassed = 192
                        }
                        if(status == 'negotiating_marinating') {
                            timestampKey = 'negotiating_marinating_at'
                            maxTimePassed = 96
                        }
                        if(this.activeInquiries) 
                        this.activeInquiries = this.calculateValuation(this.activeInquiries, timestampKey, maxTimePassed)
                        
                    }
                    this.activeTab = status
                    this.getInquiriesMetadata()
                    // this.getNewInquiries(this.activeTab)
                }
            })
        },
        getInquiriesMetadata: async function() {
            // this.loading = true
            fetch(this.$fetchIp+`/api/form/metadata`, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                if(res) {
                    this.inquiriesMetadata = {
                        'new': 0,
                        'pending': 0,
                        'pending_sour': 0,
                        'negotiating': 0,
                        'negotiating_marinating': 0,
                        'negotiating_sour': 0,
                        'resolved': 0,
                        'resolved_sold': 0,
                        'resolved_lost': 0,
                        'finalized': 0,
                    }
                    if(this.viewMode == 'isolated') {
                        // status counts of active user
                        res.data.statusCountPerAssignee.forEach(item => {
                            const { assignee, status, status_count } = item;
                            if (assignee === this.displayName) {
                                this.inquiriesMetadata[status] = status_count;
                            }
                            if (status === 'new') {
                                this.inquiriesMetadata[status] = status_count
                            }
                        });
                    }
                    if(this.viewMode == 'overall') {
                        // overall status counts
                        res.data.statusCountPerAssignee.forEach(item => {
                            const { status, status_count } = item;
                            
                            // Increment the count for the corresponding status
                            this.inquiriesMetadata[status] += status_count;
                        });
                    }
                    this.loading = false
                }
            })
            // const resp = {"status":"success","data":{"statusCountPerAssignee":[{"assignee":null,"status":"new","status_count":24},{"assignee":null,"status":"pending","status_count":1},{"assignee":null,"status":"resolved","status_count":6},{"assignee":"Cupella","status":"pending","status_count":5},{"assignee":"Cupella","status":"resolved","status_count":9},{"assignee":"kammtupik565","status":"new","status_count":3},{"assignee":"kammtupik565","status":"pending","status_count":49},{"assignee":"kammtupik565","status":"resolved","status_count":13},{"assignee":"Kristi Paalman","status":"pending","status_count":7},{"assignee":"Kristi Paalman","status":"resolved","status_count":2},{"assignee":"KristjanPoll","status":"pending","status_count":11},{"assignee":"KristjanPoll","status":"resolved","status_count":7}]}}
            
        },

        calculateValuation(data, timestampKey, maxTimePassed) {
            // Loop through each inquiry object
            data.forEach(inquiry => {
                // Extract the timestamp from the object
                let createdTimestamp = new Date(inquiry[timestampKey]).getTime();
                let currentTime = new Date().getTime();

                // Ensure currentTime is not before createdTimestamp
                currentTime = Math.max(currentTime, createdTimestamp);

                let timeDifference = currentTime - createdTimestamp;

                // Convert milliseconds to hours
                let hoursPassed = timeDifference / (1000 * 60 * 60);

                // Calculate percentage of time passed relative to maximum time
                let percentage = Math.min(hoursPassed / maxTimePassed * 100, 100);

                // Calculate valuation in steps of 1 percent, ensuring minimum valuation is 10
                let valuation = Math.max(Math.round(100 - percentage), 15);

                // Add the timePassedSinceCreated and valuation properties to the inquiry object
                inquiry.timePassedSinceLastChange = hoursPassed;
                inquiry.healthValuation = valuation;
            });

            // Return the modified data array
            return data;
        },

        getWooCommerceProducts: async function() {
            // Set your WooCommerce API credentials
            const consumerKey = 'ck_523a404faf7a65ad08551941adf3981d97d77d56';
            const consumerSecret = 'cs_beb6df010e20118201026eac96d008c1da3277cb';

            // Set your WooCommerce store URL
            const storeUrl = 'https://cupella.ee';

            // API endpoint for retrieving products
            const apiEndpoint = `${storeUrl}/wp-json/wc/v3/products`;

            // Set query parameters (optional)
            const queryParams = {
                per_page: 100, // Number of products per page
                status: 'publish',
            };

            // Set up the authentication headers
            const headers = {
                Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
            };

            /// Construct the URL with query parameters
            const url = new URL(apiEndpoint);
            url.search = new URLSearchParams(queryParams);

            // Make the API request using fetch
            fetch(url, { headers })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${ response.status }`);
                }
                return response.json();
            })
            .then(data => {
                var productsArray = data
                productsArray.forEach(obj => {
                    // Find the "attributes" array
                    const attributesArray = obj.attributes;

                    // Check if "attributes" array exists and has elements
                    if (attributesArray && Array.isArray(attributesArray) && attributesArray.length > 0) {
                        // Find the object with key "name" equal to 'googleFormLink' within "attributes" array
                        const googleFormLinkObject = attributesArray.find(attr => attr.name === 'googleFormLink');

                        // Check if the object is found
                        if (googleFormLinkObject && Array.isArray(googleFormLinkObject.options) && googleFormLinkObject.options.length > 0) {
                        // Update the first object with "googleFormLink"
                        obj.googleFormLink = googleFormLinkObject.options[0];
                        }
                    } else {
                        // If "attributes" key is not found, add "googleFormLink: false" to the first object
                        obj.googleFormLink = false;
                    }
                });
                this.productsET = productsArray.filter(obj => !obj.tags.some(tag => tag.name === 'en'))
                this.productsEN = productsArray.filter(obj => obj.tags.some(tag => tag.name === 'en'))

                this.activeProducts = this.productsET
            })
            .catch(error => {
                console.error('Error:', error.message);
            });
        },
    },
    created() {
        this.changeTab(this.activeTab)
        // this.getProductionOrders()
        // this.getWooCommerceProducts()
        this.getInquiriesMetadata()
        this.getInquiriesByStatus('new')
    }
}
</script>

<style scoped>
h2 {
    font-family: "Quicksand", sans-serif;
}
.overviewHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.pageSplit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.unassignedInquiries {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 15px;
    -webkit-column-gap: 25px;
       -moz-column-gap: 25px;
            column-gap: 25px;
}
h2 {
    margin: 0;
    margin-bottom: 25px;
}
.contentBody {
    width: 1650px;
    padding-top: 50px;
    padding-left: 250px;
}
.productsGrid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 25px;
    -webkit-column-gap: 25px;
       -moz-column-gap: 25px;
            column-gap: 25px;
    padding-bottom: 75px;
}
.tabs {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid rgb(230,230,230);
    padding-bottom: 5px;
    margin-bottom: 25px;
    color: rgb(125,125,125);
}
.tabs p {
    margin: 0;
    margin-right: 25px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'quicksand', sans-serif;
    
}
/* .tabs p:last-child {
    position: absolute;
    right: 0;
    margin-right: 0;
    color: rgb(8, 184, 110);
} */
.tabActive {
    color: steelblue;
}
.p-progress-circle {
    background: steelblue;
}
@media(max-width: 1690px) {
    .contentBody {
        width: 99%;
        padding-left: 25px;
        padding-right: 15px;
    }
}
@media(max-width: 600px) {
    .contentBody {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media(max-width: 630px) {
    .tabs {
        padding-bottom: 10px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .tabs p:last-child {
        position: relative;
    }
}
</style>
  