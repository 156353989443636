<template>
    <div 
        class="singleBtn"
        v-if="!productCode[index]"
        style="width: 37%"
        @click="toggle"
        label="Confirm" id="confirmButton"
    >
        <p style="width: 100%; right: 0; text-align: center">Uus toode</p>
        
    </div>
    <OverlayPanel ref="op" showCloseIcon>
        <div class="overlayBody">
            <h2>Uus tootetüüp laosüsteemi</h2>
            <p class="errorMessage" v-if="error">{{errorMsg}}</p>

            <!-- <div class="p-float-label">
                <Dropdown 
                    class="overlayDropdown"
                    v-model="selectedCategory" 
                    :options="productTags"
                    optionLabel="name" 
                    inputId="dd-city"
                />
                <label for="dd-city">Vali kategooria</label>
            </div> -->

            <span class="p-float-label">
                <InputText class="inputText" id="productName" v-model="productName" />
                <label for="productName">Toote nimetus</label>
            </span>

            <div 
                class="singleBtn"
                @click="confirmNewProduct(productName); toggleOff(productName)"
            >
                <p>Kinnita tootetüüp</p>
            </div>
            <successAnimation v-if="successAnimationActive" />
        </div>
    </OverlayPanel>
</template>

<script setup>
import { ref } from "vue";

const op = ref();

const toggle = (event) => {
    op.value.toggle(event);
}
const toggleOff = (productName) => {
    if(productName) {
        setTimeout(() => {
            op.value.hide();
        }, 1500)
    }
}
</script>

<script>
import OverlayPanel from 'primevue/overlaypanel';
// import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import successAnimation from '@/components/primeVue/success.vue'
export default {
    name: 'dynamicPopup',
    components: {
        OverlayPanel,
        // Dropdown,
        InputText,
        successAnimation
    },
    props: {
        productData: null,
        productTags: {},
        productCode: {},
        index: null,
    },
    watch: {
        productData(value) {
            this.productName = value.name
        }
    },
    data() {
        return {
            selectedCategory: null,
            productName: null,
            successAnimationActive: false,
            error: false,
            errorMsg: null
        }
    },  
    methods: {
        confirmNewProduct(productName) {
            if(productName) {
                this.error = false
                this.successAnimationActive = true

                
                setTimeout(() => {
                    this.successAnimationActive = false
                    this.$emit('addNewProductToList', productName, this.index)
                }, 1500)
            } else {
                this.error = true
                this.errorMsg = 'Uue tootetüübi sisestamiseks tuleb sisestada nimetus'
            }

        }
    },
    created() {
        this.productName = this.productData.name
    }
}
</script>

<style scoped>
.overlayBody {
    position: relative;
    width: 350px;
    height: 160px;
}
.overlayBody h2 {
    margin: 0;
    margin-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
}
.errorMessage {
    position: absolute;
    top: -20px;
    z-index: 100;
    background: red;
    padding: 5px 5px 5px 13px;
    color: white;
    border-radius: 10px;
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
}
.overlayDropdown {
    width: 200px;
}
.p-float-label {
    height: 25px;
    margin-bottom: 35px;
}
.p-float-label label {
    margin-top: -4px;
}
.inputText {
    height: 34px;
}

.singleBtn {
    position: relative;
    background: #4868D1;
    height: 33px;
    width: 200px;
    border-radius: 5px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin-top: 0px;
}
.singleBtn:last-child {
    margin-bottom: 0;
}
.singleBtn p {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 0;
    width: 200px;
    margin: auto;
    height: 23px;
    color: white;
    font-family: 'quicksand', sans-serif;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
}
.singleBtn img {
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 12px;
    width: 25px;
    height: 25px;
}

@media (max-width: 430px) {
   
}
</style>