<template>
    <div>
        <div class="inquiryNumbers">
            <div class="singleContainer" style="background: #E1E5EA">
                <h3 style="color: #3C5B6F"> Päringuid kokku</h3>
                <p>{{ totalLeadCount }}</p>
            </div>
            <div class="singleContainer" style="background: #C5ECBE">
                <h3 style="color: #3C5B6F">Müüdud</h3>
                <p>{{ inquiriesMetadata.finalized }}</p>
            </div>
            <div class="singleContainer" style="background: #E2F6CA">
                <h3 style="color: #3C5B6F">Ettemakse sooritatud</h3>
                <p>{{ inquiriesMetadata.resolved_sold }}</p>
            </div>
            <div class="singleContainer" style="background: #F8FDCF">
                <h3 style="color: #3C5B6F">Läbirääkimisel</h3>
                <p>{{ inquiriesMetadata.negotiating_marinating }}</p>
            </div>
            <div class="singleContainer" style="background: #FFEAA7">
                <h3 style="color: #3C5B6F">Läbikukkunud</h3>
                <p>{{ inquiriesMetadata.resolved_lost }}</p>
            </div>
            <div class="singleContainer">
                <h3>Jaotamata</h3>
                <p>{{ inquiriesMetadata.new }}</p>
            </div>
            <div class="singleContainer">
                <h3>Saatmata</h3>
                <p>{{ inquiriesMetadata.pending }}</p>
            </div>
            <div class="singleContainer">
                <h3>Saatmata (hapu)</h3>
                <p>{{ inquiriesMetadata.pending_sour }}</p>
            </div>
            <div class="singleContainer">
                <h3>Saadetud</h3>
                <p>{{ inquiriesMetadata.negotiating }}</p>
            </div>
            <div class="singleContainer">
                <h3>Saadetud (hapu)</h3>
                <p>{{ inquiriesMetadata.negotiating_sour }}</p>
            </div>
        </div>
        <div style="display: flex; justify-content: space-between">
            <DataTable :value="top5products" tableStyle="width: 920px; border: 1px solid rgb(230,230,230); border-radius: 10px; overflow: hidden">
                <Column field="productName" header="Toode">
                    <template #body="slotProps">
                        <p>{{ slotProps.data.productName }}</p>
                        <ButtonPrime text style="height: 30px; font-size: 12px; margin-top: -10px;" type="button" severity="secondary" @click="console.log(slotProps.data.slug)" icon="pi pi-external-link" label="Tootelehele"/>
                    </template>
                </Column>
                <Column header="Kaanepilt">
                    <template #body="slotProps">
                        <img
                            class="w-8rem border-round"
                            style="margin-top: 5px"
                            :src="require(`@/assets/coverImgs/${slotProps.data.product}_cover.png`)"
                        >
                    </template>
                </Column>
                <Column field="series" header="Kategooria"></Column>
                <Column field="sales_count">
                    <template #header>
                        <p style="text-align: center; width: 100%">Päringute arv</p>
                    </template>
                    <template #body="slotProps">
                        <p style="text-align: center; font-weight: bold; font-size: 20px; color: steelblue; font-family: 'DM sans', sans-serif">{{ slotProps.data.sales_count }}<span style="color: black; margin-left: 10px; margin-right:10px; font-weight: 100;">|</span><span style="color: green;font-weight: 100;">{{ slotProps.data.percentOfTotal }}%</span></p>
                    </template>
                </Column>
            </DataTable>
            <DataTable scrollable scrollHeight="550px" :value="leadsByCountry" tableStyle="width: 450px; border: 1px solid rgb(230,230,230); border-radius: 10px; overflow: hidden">
                <Column field="country.name" header="Riik">
                    <template #body="slotProps">
                        <CountryFlag style="margin-right: 10px" :iso="slotProps.data.country.code"  />
                        <span>{{ slotProps.data.country.name }}</span>
                    </template>
                </Column>
                <Column field="percentOfTotal">
                    <template #body="slotProps">
                        <p style="text-align: center; font-size: 14px; color: green; font-family: 'DM sans', sans-serif">{{ slotProps.data.percentOfTotal }}%</p>
                    </template>
                </Column>
                <Column field="lead_count">
                    <template #header>
                        <p style="text-align: center; width: 100%">Päringute arv</p>
                    </template>
                    <template #body="slotProps">
                        <p style="text-align: center; font-weight: bold; font-size: 20px; color: steelblue; font-family: 'DM sans', sans-serif">{{ slotProps.data.lead_count }}</p>
                    </template>
                </Column>
            </DataTable>
        </div>

        <h3 style="font-family: 'quicksand', sans-serif; margin-bottom: -10px;">Müügiagendid</h3>
        <div class="salesAgents">
            <div class="singleAgent" v-for="element of assignees" :key="element.id">
                <h3>{{element.assigneeName}}</h3>
                <div class="flex">
                    <div>
                        <p>Kokku</p>
                        <span>{{ element.totalStatusCount }} päringut</span>
                    </div>
                    <div class="conversion">
                        <p>Müügimäär</p>
                        <span>{{ element.conversionRate }}%</span>
                    </div>
                    <div>
                        <p>Viimasel nädalal</p>
                        <span>{{ element.lastSalesWeek.sales_count }} päringut</span>
                    </div>
                </div>
                <div class="statuses">
                    <Tag style="background: #41B06E" icon="pi pi-check" :value="'Müüdud ('+ (element.statuses.finalized + element.statuses.resolved_sold) +')'"></Tag>
                    <Tag style="background: #1679AB" icon="pi pi-hourglass" :value="'Läbirääkimisel ('+ element.statuses.negotiating_marinating +')'"></Tag>
                    <Tag icon="pi pi-times" severity="warning" :value="'Läbikukkunud ('+ element.statuses.resolved_lost +')'"></Tag>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountryFlag from 'vue3-country-flag-icon'
import 'vue3-country-flag-icon/dist/CountryFlag.css'
import productParams from '../components/data/productParams.json'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import ColumnGroup from 'primevue/columngroup'; 
// import Row from 'primevue/row'; 
import Tag from 'primevue/tag';

export default {
    name: 'salesStats',
    components: {
        CountryFlag,
        DataTable,
        Column,
        Tag
        // ColumnGroup,
        // Row
    },
    watch: {
        
    },
    props: {
        
    },
    data () {
        return {
            productParams: productParams,
            allMetadata: [],
            leadsByCountry: [],
            top5products: [],
            inquiriesMetadata: {},
            assignees: [],

            totalLeadCount: 0,
        }
    },
    methods: {
        getInquiriesMetadata: async function() {
            // this.loading = true
            fetch(this.$fetchIp+`/api/form/metadata`, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json()) 
            .then((res) => {
                this.allMetadata = res.data
                this.leadsByCountry = res.data.leadsByCountry
                this.leadsByCountry.sort((a, b) => b.lead_count - a.lead_count);

                this.totalLeadCount = this.leadsByCountry.reduce((total, country) => total + country.lead_count, 0);
                this.leadsByCountry.forEach(country => {
                    country.percentOfTotal = Math.round((country.lead_count / this.totalLeadCount) * 100);
                });

                this.leadsByCountry = this.leadsByCountry.slice(0, 7);



                const productsData = res.data.top5products

                this.top5products = [];

                productsData.forEach(item => {
                    const productInfo = this.productParams[item.product]?.et || {};

                    const productName = productInfo.productName || "";
                    const series = productInfo.series || "";
                    const slug = productInfo.slug || "";

                    this.top5products.push({ 
                        product: item.product, 
                        sales_count: item.sales_count, 
                        productName: productName,
                        series: series,
                        slug: slug
                    });
                });

                this.top5products.forEach(product => {
                    product.percentOfTotal = Math.round((product.sales_count / this.totalLeadCount) * 100);
                });

                this.inquiriesMetadata = {
                    'new': 0,
                    'pending': 0,
                    'pending_sour': 0,
                    'negotiating': 0,
                    'negotiating_marinating': 0,
                    'negotiating_sour': 0,
                    'resolved': 0,
                    'resolved_sold': 0,
                    'resolved_lost': 0,
                    'finalized': 0,
                }
                res.data.statusCountPerAssignee.forEach(item => {
                    const { status, status_count } = item;
                    this.inquiriesMetadata[status] += status_count;
                });

                const data = res.data;

                // Initialize an empty array to store the assignee objects
                this.assignees = [];

                // Get all unique statuses
                const allStatuses = Object.keys(this.inquiriesMetadata);

                // Iterate through the assignedLeadsTotalPerAssignee array to gather assignee names
                data.assignedLeadsTotalPerAssignee.forEach(assigneeData => {
                    const assigneeName = assigneeData.assignee;

                    // Skip if assignee is null
                    if (!assigneeName) return;

                    // Initialize an object to store all status counts for the current assignee
                    const statusCounts = {};

                    // Initialize all status counts for the current assignee to 0
                    allStatuses.forEach(status => {
                        statusCounts[status] = 0;
                    });

                    // Update status counts for the current assignee
                    data.statusCountPerAssignee.forEach(statusData => {
                        if (statusData.assignee === assigneeName) {
                            statusCounts[statusData.status] = statusData.status_count;
                        }
                    });

                    // Find the total status count for the current assignee
                    const totalStatusCount = Object.values(statusCounts).reduce((total, count) => total + count, 0);

                    // Find the conversion rate for the current assignee
                    const finalizedCount = statusCounts["finalized"] || 0;
                    const resolvedSoldCount = statusCounts["resolved_sold"] || 0;
                    const resolvedLostCount = statusCounts["resolved_lost"] || 0;
                    const conversionRate = ((finalizedCount + resolvedSoldCount) / resolvedLostCount * 100 || 0).toFixed(2);

                    // Find the last sales week sales count for the current assignee
                    const lastSalesWeek = data.assigneeLeadsPerWeek.reduce((lastWeek, weekData) => {
                        if (weekData.assignee === assigneeName && (!lastWeek || weekData.sales_week > lastWeek.sales_week)) {
                            return weekData;
                        }
                        return lastWeek;
                    }, null);

                    // Create the assignee object
                    const assignee = {
                        assigneeName: assigneeName,
                        statuses: statusCounts,
                        totalStatusCount: totalStatusCount,
                        conversionRate: parseFloat(conversionRate),
                        lastSalesWeek: lastSalesWeek ? { sales_week: lastSalesWeek.sales_week, sales_count: lastSalesWeek.sales_count } : null
                    };

                    // Add the assignee object to the array
                    this.assignees.push(assignee);
                });

            })            
        },
    },

    created() {
        this.getInquiriesMetadata()
    }
}
</script>
<style>
th {
    padding-top: 0;
    padding-bottom: 0;
    background: rgb(240, 240, 240);
}
td {
    padding-top: 0px;
    padding-bottom: 0px;
}
.p-tag {
    position: relative;
    width: 200px;
    font-size: 16px;
    font-weight: 400;
}
.p-tag-icon {
    position: absolute;
    font-size: 16px;
    left: 10px;
}
.p-tag-value {
    margin-left: 10px;
}
</style>
<style scoped>
.inquiryNumbers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 15px;
}
.singleContainer {
    width: 19%;
    border: 1px solid rgb(230,230,230);
    border-radius: 10px;
    background: white;
    margin-bottom: 10px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.singleContainer h3 {
    margin: 0;
    font-family: "quicksand", sans-serif;
    color: #94a0ac;
}
.singleContainer p {
    margin: 0;
    margin-top: 10px;
    color: #32465a;
    font-size: 28px;
}
.singleRow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
}
.singleRow p {
    margin: 0;
    margin-left: 10px;
}
.salesAgents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 50px;
}
.singleAgent {
    width: 49%;
    padding: 20px;
    margin-bottom: 15px;
    border: 1px solid rgb(230,230,230);
    border-radius: 10px;
    background: white;
}
.singleAgent h3 {
    margin: 0;
    font-family: 'quicksand', sans-serif;
    font-size: 20px;
}
.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(230,230,230);
}
.statuses {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.flex p {
    color: rgb(100,100,100);
    margin-bottom: 5px;
}
.flex span {
    font-weight: bold;
    font-family: 'DM sans', sans-serif;
}
.flex div:last-child {
    text-align: end;
}
.flex div:nth-child(2) {
    text-align: center;
}
.conversion span {
    font-size: 26px;
    color: green;
}
.conversion p {
    margin-bottom: 2px;
}
</style>
  