<template>
    <div class="container" @contextmenu="showContextMenu">
        <div class="category">
            <i class="pi pi-link" style="position: absolute; left: 10px;font-size: 17px;"></i>
            <span v-for="element of singleProductData.categories" :key="element.id">/{{ element.name }}</span>
        </div>
        <img class="productImg" :src='singleProductData.images[0].src'>
        <p class="productName">{{ singleProductData.name }}</p>
        <p class="notice" v-if="!singleProductData.googleFormLink"><i class="pi pi-exclamation-triangle" style="font-size: 14px;"></i> Hinnapäringu vorm puudub!</p>
    </div>
    <ContextMenu ref="menu" :model="items" />
    <Toast ref="toastComp" />
</template>
<script>
import ContextMenu from 'primevue/contextmenu';
import Toast from 'primevue/toast';

export default {
    name: 'singleProduct',
    components: {
        ContextMenu,
        Toast
    },
    props: {
        singleProductData: {}
    },
    watch: {
       
    },

    data () {
        return {
            items: [
                { 
                    label: 'Vaata toodet', 
                    icon: 'pi pi-eye',
                    command: () => {
                        var linkUrl = this.singleProductData.permalink
                        window.open(linkUrl, '_blank');
                    }
                },
                { 
                    label: 'Muuda toodet', 
                    icon: 'pi pi-file-edit',
                    command: () => {
                        var linkUrl = `https://cupella.ee/wp-admin/post.php?post=${this.singleProductData.id}&action=edit`
                        window.open(linkUrl, '_blank');
                    }
                },
                { 
                    label: 'Hinnapäring', 
                    icon: 'pi pi-folder-open', 
                    items: [
                        {
                            label: 'Ava vorm',
                            icon: 'pi pi-external-link',
                            command: () => {
                                if(!this.singleProductData.googleFormLink) {
                                    this.$refs.toastComp.add({ severity: 'error', summary: 'Tootel puudub hinnapäringu vorm!', life: 3000 })
                                } else {
                                    var linkUrl = this.singleProductData.attributes[0].options[0]
                                    window.open(linkUrl, '_blank');
                                }
                            }
                        },
                        {
                            label: 'Kopeeri link',
                            icon: 'pi pi-copy',
                            command: () => {
                                if(!this.singleProductData.googleFormLink) {
                                    this.$refs.toastComp.add({ severity: 'error', summary: 'Tootel puudub hinnapäringu vorm!', life: 3000 })
                                } else {
                                    navigator.clipboard.writeText(this.singleProductData.attributes[0].options[0])
                                    this.$refs.toastComp.add({ severity: 'info', summary: 'Toote hinnapäringu vormi link kopeeritud!', life: 3000 })
                                }
                            }
                        }
                    ]}
            ]
        }
    },
    methods: {
        showContextMenu(event) {
            console.log(event)
            this.$refs.menu.show(event)
        }
        
    },
    created() {
        
    }
}
</script>

<style scoped>
.container {
    position: relative;
    overflow: hidden;
    width: 318px;
    height: 300px;
    border-radius: 10px;
    background: white;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}
.category {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 3px;
    background: white;
    color: silver;
    text-align: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    max-width: 318px; /* Adjust the max-width as needed */
    display: inline-block;
    font-size: 13px;
}

.productImg {
    width: 100%;
    height: 200px;
    -o-object-fit: contain;
       object-fit: contain;
    border-bottom: 1px solid rgb(230,230,230);
}
.productName {
    text-align: center;
    margin: 0;
    margin-top: 5px;
    font-weight: bold;
    font-family: 'quicksand', sans-serif;
    color: rgb(50,50,50);
}
.notice {
    color: rgb(150,150,150);
    text-align: center;
    font-size: 14px;
}
</style>