<template>
    <div class="container">
        <div class="screen">
            <p class="errorMessage" v-if="errors">{{errors}}</p>
            <div class="screen__content">
                <div class="login">
                    <img 
                        class="logoImg"
                        :src="require('@/assets/cupella.jpg')" 
                        alt=""
                    >
                    <div class="login__field">
                        <i class="login__icon pi pi-user"></i>
                        <input type="text" v-model="email" class="login__input" placeholder="E-posti aadress">
                    </div>
                    <div class="login__field">
                        <i class="login__icon pi pi-lock"></i>
                        <input type="password" v-model="password" class="login__input" placeholder="Salasõna">
                    </div>
                    <button class="button login__submit" @click="initAuth">
                        <span class="button__text">Sisene süsteemi</span>
                        <i class="button__icon pi pi-sign-in"></i>
                    </button>				
                </div>
                <div class="social-login">
                    <h3>Laosüsteem</h3>
                    <h3>Cupella.storix</h3>
                </div>
            </div>
            <div class="screen__background">
                <span class="screen__background__shape screen__background__shape4"></span>
                <span class="screen__background__shape screen__background__shape3"></span>		
                <span class="screen__background__shape screen__background__shape2"></span>
                <span class="screen__background__shape screen__background__shape1"></span>
            </div>		
        </div>
    </div>
</template>

<script>

export default {
    name: 'loginView',
    data() {
        return {
            email: null,
            password: null,
            errors: null
        }
    },  
    methods: {
        initAuth() {
            fetch(`${this.$storiumIp}/v2/auth/password`, {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({"email": this.email, "password": this.password})
            })
            .then((res) => res.json())
            .then((res) =>  {
                if (res.message === "Wrong password." ) {
                    this.errors = null
                    this.errors = 'Sisestatud parool on vale'
                } 
                if (res.message === "User must have a valid email." ) {
                    this.errors = null
                    this.errors = 'Antud e-mail ei ole korrektne'
                } 
                if (res.message === "User must have a password." ) {
                    this.errors = null
                    this.errors = 'Sisestage soovitud parool'
                } 
                if (res.message === "User with the provided email does not exist." ) {
                    this.errors = null
                    this.errors = 'Antud e-maili andmebaasis ei eksisteeri'
                } 
                if (res.message === "Privileged users are not allowed to authenticate themselves by regular endpoint." ) {
                    this.errors = null
                    this.errors = 'Administraatorid ja moderaatorid ei saa sisse logida tavakasutajana, kasutage otspunkti /admin/login'
                } 
                if (res.message === "Something went wrong fetching google user data from Google." ) {
                    this.errors = null
                    this.errors = 'Midagi läks valesti Googlelt andmete saamisega, palun proovige uuesti'
                } 
                if (res.message === "Error opening a new session with the provided information." ) {
                    this.errors = null
                    this.errors = 'Midagi läks valesti, palun proovige uuesti'
                } 
                if (res.status === "success" ) {
                    this.errors = null
                    this.$emit('authSuccess')
                } 
            })
        }
    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

* {
	-webkit-box-sizing: border-box;
    box-sizing: border-box;
	margin: 0;
	padding: 0;	
	font-family: 'Quicksand', sans-serif;
}


.container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
}
.errorMessage {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 100;
    background: red;
    color: white;
    text-align: center;
    font-weight: bold;
}
.screen {		
	background: -o-linear-gradient(left, #bae9e0, #42c29b);		
	background: -webkit-gradient(linear, left top, right top, from(#bae9e0), to(#42c29b));		
	background: linear-gradient(90deg, #bae9e0, #42c29b);		
	position: relative;	
	height: 600px;
	width: 360px;	
	-webkit-box-shadow: 0px 0px 24px #568996;	
	box-shadow: 0px 0px 24px #568996;
    border-radius: 30px 5px 30px 5px;
    overflow: hidden;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #639eac;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: -o-linear-gradient(right, #2eb4cc, #679a9e);
	background: -webkit-gradient(linear, right top, left top, from(#2eb4cc), to(#679a9e));
	background: linear-gradient(270deg, #2eb4cc, #679a9e);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #2eb4cc;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}
.logoImg {
    width: 207px;
    margin-left: -9px;
    margin-top: -120px;
}

.login {
	width: 320px;
	padding: 30px;
	padding-top: 154px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 29px;
	color: #2b6374;
    font-size: 20px;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 32px;
	font-weight: 700;
	width: 75%;
	-webkit-transition: .2s;
	-o-transition: .2s;
	transition: .2s;
    font-size: 15px;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #679e97;
}

.login__submit {
	background: #fff;
	font-size: 18px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #85b4a2;
	text-transform: uppercase;
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 91%;
	color: #2b6374;
	-webkit-box-shadow: 0px 2px 7px #bebdc5;
	box-shadow: 0px 2px 7px #bebdc5;
	cursor: pointer;
	-webkit-transition: .2s;
	-o-transition: .2s;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #2b6374;
}

.social-login {	
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: -70px;
	right: 0px;
	color: #fff;
}

.social-icons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
	-webkit-transform: scale(1.5);
	-ms-transform: scale(1.5);
	transform: scale(1.5);	
}

@media (max-width: 430px) {
   
}
</style>