<template>
    <div class="container">
        <i class="msgIcon pi pi-verified"></i>
        <h2>Tooted edukalt saadetud lattu!</h2>
    </div>
</template>

<script>
export default {
    name: 'successMsg',
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.container {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 10px;
    right: 55px;
    background: rgba(15, 167, 103, 0.808);
    width: 250px;
    padding: 10px;
    padding-right: 20px;
    border-radius: 5px;
    opacity: 0;
    -webkit-animation: myAnimation .3s ease-in-out forwards;
            animation: myAnimation .3s ease-in-out forwards;
}
h2 {
    font-size: 20px;
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    font-family: 'quicksand', sans-serif;
    text-align: center;
}
.msgIcon {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
}
@-webkit-keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(80px);
                transform: translateY(80px);
    }
}
@keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(80px);
                transform: translateY(80px);
    }
}
@media (max-width: 430px) {
   
}
</style>