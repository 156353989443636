<template>
    <div class="wrapper" :style="{'width': width, 'height': height, 'top': top}">
        <svg class="checkmark" :style="{ 'width': checkmarkWidth, 'height': checkmarkHeight }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'successAnimation',
    props: {
        width: {
            default: '350px'
        },
        height: {
            default: '160px'
        },
        top: {
            default: '0'
        },
        checkmarkHeight: {
            default: '150px'
        },
        checkmarkWidth: {
            default: '150px'
        }
    },
    data() {
        return {

        }
    },
}
</script>

<style scoped>
.wrapper {
    position: absolute;
    z-index: 10000;
    background: rgba(240, 240, 240, 0.5);
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #64C3A2;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #64C3A2;
    stroke-miterlimit: 10;
    -webkit-box-shadow: inset 0px 0px 0px #ffffff;
            box-shadow: inset 0px 0px 0px #ffffff;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@-webkit-keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 800px #ffffff;
                box-shadow: inset 0px 0px 0px 800px #ffffff;
    }
}
@keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 800px #ffffff;
                box-shadow: inset 0px 0px 0px 800px #ffffff;
    }
}
</style>
