<template>
    <div class="app">
        <loadingScreen v-if="isPageLoading" />
        <div class="bodyWrapper" v-else>
            <div class="contentB" v-if="isAuth">
                <ledgerView v-if="!isDisabled" @backgroundSwitch='isDarkBackgroundActive = !isDarkBackgroundActive' :closePopup='closePopup' />
                <inquiriesView :displayName="displayName" />
                <!-- <span class="logoutBtn" @click="initLogout()">Logi välja</span> -->
            </div>
            <loginView v-if="!isAuth" @authSuccess='auth()' />
        </div>
        <div class="background" @click="closePopup ++; isDarkBackgroundActive = false" v-if="isDarkBackgroundActive"></div>
    </div>
</template>

<script>
import 'primevue/resources/themes/lara-light-blue/theme.css';

import ledgerView from './views/ledger.vue'
import loginView from './views/login.vue'

import inquiriesView from './views/inquiries.vue'

import loadingScreen from '@/components/animations/loadingScreen.vue'

export default {
    name: 'App',
    components: {
        ledgerView,
        loginView,
        inquiriesView,
        loadingScreen
    },
    data () {
        return {
            activeTab: 'business',
            isDarkBackgroundActive: false,
            closePopup: 0,

            isAuth: null,
            displayName: null,
            isDisabled: true,
            isPageLoading: false
        }
    },
    methods: {
        auth() {
            this.isPageLoading = true
            this.isAuth = true
            setTimeout(() => {
                this.isPageLoading = false
            }, 2950)
        },
        changeTab(nextTab) {
            this.activeTab = nextTab
        },

        initLogout() {
            fetch(`${this.$storiumIp}/v2/auth/logout`, {
                method: 'POST',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                if (!res.isAuthenticated) {
                    this.isAuth = false
                }
            })
        },

        roundtripRegulator() {
            fetch(`${this.$fetchIp}/api/roundtrip`, {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.isAuthenticated) {
                    this.isAuth = true
                    this.displayName = res.displayName
                } else {
                    this.isAuth = false
                }
            })
            .catch((err) => {
                this.catchError = err
            }) 
        },
    },
    created() {
        this.roundtripRegulator()
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600&family=Oswald:wght@300&family=Quicksand:wght@300..700&display=swap');

body {
    padding: 0;
    margin: 0;
    background: rgb(249, 253, 255);
}
.app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
}
.bodyWrapper {
    padding-top: 0px;
    width: 100%;
}
.background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
</style>
