<template>
    <div class="">
        <h2>Laoraamatu sissekanne nr.{{ stockRecordData.idRecord }}</h2>
        <h2 style="margin: 0">LADU</h2>
        <div class="productTable">
            <div class="index">
                <p>Toode</p>
                <p>Kategooria</p>
                <p>Laojääk</p>
                <p>Viimatine muutus</p>
                <p>Ühiku hind</p>
                <p>Väärtus</p>
            </div>
            <div class="table">
                <div 
                    class="singleItem"
                    v-for="(element, index) in stockRecordData.itemList" :key="index"
                >
                    <p>{{ element.name }}</p>
                    <p>Puit</p>
                    <p>{{ element.quantity }}</p>
                    <p>+365</p>
                    <p>{{ element.unitPrice }}</p>
                    <p>{{ element.totalSum }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'stockRecordReceipt',
    props: {
        stockRecordData: {}
    },
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.index {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid rgb(220,220,220);
}
.index p {
    width: 16%;
}
.index p:first-child {
    text-align: start;
}
.index p:last-child {
    text-align: end;
}
.singleItem {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: center;
}
.singleItem p {
    width: 16%;
}
.singleItem p:first-child {
    text-align: start;
}
.singleItem p:last-child {
    text-align: end;
}

@media (max-width: 430px) {
   
}
</style>