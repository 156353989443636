<template>
    <div class="wrapper">
        <div 
            class="singleMenuItem" 
            :class="{ 'singleMenuItemActive': activeTab == 'inquiries' }" 
            @click="changeCurrentTab('inquiries')"
        >
            <i class="pi pi-folder-open"></i>
            <p>Hinnapäringud</p>
        </div>
        <div 
            class="singleMenuItem" 
            :class="{ 'singleMenuItemActive': activeTab == 'salesStats' }" 
            @click="changeCurrentTab('salesStats')"
        >
            <i class="pi pi-chart-line"></i>
            <p>Statistika</p>
        </div>
        <!-- <div 
            class="singleMenuItem" 
            :class="{ 'singleMenuItemActive': activeTab == 'inquiries' }" 
            @click="changeCurrentTab('inquiries')"
        >
            <i class="pi pi-folder-open"></i>
            <p>Kõik päringud</p>
        </div> -->
        <!-- <div class="singleMenuItem" :class="{ 'singleMenuItemActive': activeTab == 'productForms' }" @click="changeCurrentTab('productForms')">
            <i class="pi pi-th-large"></i>
            <p>Tooted</p>
        </div>
        <div class="singleMenuItem" :class="{ 'singleMenuItemActive': activeTab == 'singleListingPage' }" @click="changeCurrentTab('singleListingPage')">
            <i class="pi pi-th-large"></i>
            <p>Kuulutus</p>
        </div> -->
        <span class="logoutBtn" @click="initLogout()">Logi välja</span>
        <div class="bottomItem">
            <p @click="visible = true">Cupella Storix v1.0</p>
        </div>
    </div>
    <Sidebar v-model:visible="visible" header="" position="bottom" style="height: auto">
        <template #header>
            <div class="footerHeader">
                <span>Storix v1.1</span>
            </div>
        </template>
        <img 
            class="logo"
            :src="require('@/assets/cupella.jpg')"
        />
    </Sidebar>
</template>

<script>
import Sidebar from 'primevue/sidebar';

export default {
    name: 'sideMenuInquiry',
    components: {
        Sidebar
    },
    data () {
        return {
            visible: false,
            activeTab: 'inquiries'
        }
    },
    methods: {
        changeCurrentTab(nextTab) {
            this.activeTab = nextTab
            this.$emit('changeTab', nextTab)
        }
    }
}
</script>

<style scoped>
i {
    font-size: 22px;
    font-weight: 100;
    margin: 0;
    margin-top: 4px;
    margin-left: 5px;
}
.wrapper {
    position: fixed;
    background: white;
    width: 230px;
    height: 100vh;
    padding-top: 80px;
    border-right: 1px solid rgb(230,230,230);
}
.singleMenuItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 85%;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgb(230,230,230);
    color: rgb(100,100,100);
}
.singleMenuItem p {
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    margin: 0;
    padding-left: 15px;
    margin-top: 5px;
}
.singleMenuItem:hover {
    cursor: pointer;
    background: rgb(245,245,245);
}
.singleMenuItemActive {
    border-bottom: 1px solid steelblue;
    color: steelblue;
}
.bottomItem {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: rgb(125,125,125);
}
.bottomItem p:hover {
    color: steelblue;
    text-decoration: underline;
    cursor: pointer;
}
.footerHeader {
    position: absolute;
    left: 25px;
    top: 15px;
    font-weight: bold;
}
.logo {
    margin-top: -5px;
    width: 200px;

}
.logoutBtn {
    position: absolute;
    right: 0px;
    left: 0;
    bottom: 50px;
    margin: auto;
    width: fit-content;
    cursor: pointer;
    color: steelblue;
    text-decoration: underline;
}
@media(max-width: 1690px) {
    .wrapper {
        display: none;
    }
}
</style>
