<template>
    <div class="container" v-if="listingData">
        <div class="head">
            <div class="split">
                <img class="productImg" :src='listingData.images[0].src'>
                <div class="variationSelect">
                    <span><i class="pi pi-eye"></i> Vaata saunaplaani</span>
                    <p>Välise lasuuri toon</p>
                    <div>
                        <i class="pi pi-check" v-if="outsideColor == 'puit'"></i>
                        <img 
                            @click="outsideColor = 'puit'"
                            :src="require('@/assets/extras/puit_square.jpg')" 
                        >
                    </div>
                    <div>
                        <i class="pi pi-check" v-if="outsideColor == 'hall'"></i>
                        <img 
                            @click="outsideColor = 'hall'"
                            :src="require('@/assets/extras/hall_square.jpg')" 
                        >
                    </div>
                    <div>
                        <i class="pi pi-check" v-if="outsideColor == 'valge'"></i>
                        <img 
                            @click="outsideColor = 'valge'"
                            :src="require('@/assets/extras/valge_square.jpg')" 
                        >
                    </div>
                    <div>
                        <i class="pi pi-check" v-if="outsideColor == 'punane'"></i>
                        <img 
                            @click="outsideColor = 'punane'"
                            :src="require('@/assets/extras/punane_square.jpg')" 
                        >
                    </div>
                    <div>
                        <i class="pi pi-check" v-if="outsideColor == 'must'"></i>
                        <img 
                            @click="outsideColor = 'must'"
                            :src="require('@/assets/extras/must_square.jpg')" 
                        >
                    </div>
                </div>
            </div>
            <div class="split">
                <h2 class="productTitle">Cupella "mini-spa" Lux</h2>
                <div class="price">
                    <h2>9 900€</h2>
                </div>
                <div class="params">
                    <div class="singleParam">
                        <p>Mahutab</p>
                        <div class="paramIconContainer">
                            <img 
                                :src="require('@/assets/extras/params/people.svg')" 
                            >
                        </div>
                        <p>6</p>
                    </div>
                    <div class="singleParam">
                        <p>Kõrgus</p>
                        <div class="paramIconContainer">
                            <img 
                                :src="require('@/assets/extras/params/height.svg')" 
                            >
                        </div>
                        <p>2.2m</p>
                    </div>
                    <div class="singleParam">
                        <p>Laius</p>
                        <div class="paramIconContainer">
                            <img 
                                :src="require('@/assets/extras/params/width.svg')" 
                            >
                        </div>
                        <p>4.5m</p>
                    </div>
                    <div class="singleParam">
                        <p>Pikkus</p>
                        <div class="paramIconContainer">
                            <img 
                                :src="require('@/assets/extras/params/length.svg')" 
                            >
                        </div>
                        <p>2.25m</p>
                    </div>
                    <div class="singleParam">
                        <p>Kaal</p>
                        <div class="paramIconContainer">
                            <img 
                                :src="require('@/assets/extras/params/weight.svg')" 
                            >
                        </div>
                        <p>675kg</p>
                    </div>
                    <div class="singleParam">
                        <p>Materjal</p>
                        <div class="paramIconContainer">
                            <img 
                                :src="require('@/assets/extras/params/tree.svg')" 
                            >
                        </div>
                        <p>Termopuit</p>
                    </div>
                </div>
                <ButtonPrime type="button" ref="postBtn" label="Kontakteeru meiega" icon="pi pi-envelope" style="margin-right: 10px"/>
                <ButtonPrime type="button" ref="postBtn" label="Ava tooteleht" icon="pi pi-file-pdf"/>
            </div>
        </div>
        <div class="head" style="margin-top: 30px; padding-bottom: 50px;">
            <div class="split" style="border-right: 1px solid rgb(230,230,230);">
                <h3>Rohkem kui lihtsalt saun</h3>
                <p class="description">Terrassil olev kümblustünn on kolmest küljest suletud, mis kaitseb nii tuule, vihma kui ka tormi eest. Sina saad ikka mõnusalt lõõgastuda, olenemata ilmast, sest ühest küljest on sul endiselt vaade imetlemiseks. Saunas saad nautida leili, vedeleda laval ning piigeta vaateid ümmargustest akendest. Cupella MiniLux Elamus Spa pakub enneolematut 2in1 kogemust – tünn ja saun.</p>
                <Carousel :value="imagesCarousel" :numVisible="3" :numScroll="3" circular :autoplayInterval="5000" :showNavigators="false">
                    <template #item="slotProps">
                        <Image class="image" :src="slotProps.data.src" alt="Image" width="150" height="120" preview />
                    </template>
                </Carousel>
                <span class="smallTitle">Hind sisaldab</span>
                <div class="incIcons">
                    <div class="singleIncluded" @click="showImage">
                        <div class="imageContainer">
                            <img 
                                :src="require('@/assets/extras/included/keris.svg')" 
                            >
                        </div>
                        <p>Harvia M3-SL puukeris</p>
                    </div>
                    <div class="singleIncluded">
                        <div class="imageContainer">
                            <img 
                                :src="require('@/assets/extras/included/tunn.svg')" 
                            >
                        </div>
                        <p>Ahjuga kümblustünn</p>
                    </div>
                    <div class="singleIncluded">
                        <div class="imageContainer">
                            <img 
                                :src="require('@/assets/extras/included/klaasuks.svg')" 
                            >
                        </div>
                        <p>Toonklaasist saunauks</p>
                    </div>
                    <div class="singleIncluded">
                        <div class="imageContainer">
                            <img 
                                :src="require('@/assets/extras/included/kokkupandult.svg')" 
                            >
                        </div>
                        <p>Kokkupandult</p>
                    </div>
                    <div class="singleIncluded">
                        <div class="imageContainer">
                            <img 
                                :src="require('@/assets/extras/included/varv.svg')" 
                            >
                        </div>
                        <p>Lasuuri tooni valik</p>
                    </div>
                    <div class="singleIncluded">
                        <div class="imageContainer">
                            <img 
                                :src="require('@/assets/extras/included/katus.svg')" 
                            >
                        </div>
                        <p>Katuse tooni valik</p>
                    </div>
                </div>

                <iframe
                    style="margin-top: 50px; border-radius: 10px;"
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/LW6Mw2t6GvU"
                    frameborder="0"
                    allowfullscreen
                ></iframe>

            </div>
            <div class="split">
                <div>
                    <h3>Minu saun</h3>
                    <Accordion @update:activeIndex="updateIndex" :activeIndex="activeAccordionIndex">
                        <AccordionTab
                            :pt="{
                                headerAction: { class: 'accordionTab-root' },
                                content: { class: 'accordionTab-content' },
                                transition: { class: 'accordionTab-transition' }
                            }"
                        >
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <img 
                                        class="headerImg"
                                        :src="require('@/assets/extras/terrace/terass_1.jpg')" 
                                    >
                                    <div>
                                        <h3 class="accordionHeaderTitle">Cupella terrass</h3>
                                        <span>Valitud: {{ terraceSize }}m2</span>
                                    </div>
                                    <p class="extraPrice">+{{ this.terracePrice * this.terraceSize }} €</p>
                                </span>
                            </template>
                            <h3 style="margin: 0;">Hind: 90€ m2</h3>
                            <p style="margin: 0">Meie moodul-terrassi on võimalik lihtsa vaevaga paigaldada ükskõik millisele pinnasele</p>
                            <!-- <div class="accordionImages">
                                <img 
                                    :src="require('@/assets/extras/terrace/terass_1.jpg')" 
                                >
                                <img 
                                    :src="require('@/assets/extras/terrace/terass_3.jpg')" 
                                >
                            </div> -->
                            <ButtonPrime type="button" icon="pi pi-image" style="width: 180px; background: steelblue" label="Vaata pilte"/>
                            <Divider />
                            <div style="display: flex; justify-content: space-between;">
                                <div>
                                    <h3 style="margin: 0;">Ruutmeetrite kogus</h3>
                                    <InputNumber max="25" suffix=" m2" v-model="terraceSize" />
                                    
                                    <Slider max="25" style="width: 180px; margin-top: 15px; margin-bottom: 10px;" v-model="terraceSize" />
                                </div>
                                <div>
                                    <h3 style="margin: 0;">Hinnastus</h3>
                                    <p style="font-size: 24px; margin: 0; margin-top: 5px;">{{ terracePrice * terraceSize }} €</p>
                                </div>
                            </div>
                            <ButtonPrime type="button" @click="confirmExtra('terrace')" icon="pi pi-check" style="width: 100%; background: #64C3A2" label="Kinnita valik"/>
                        </AccordionTab>
                        <AccordionTab
                            :pt="{
                                headerAction: { class: 'accordionTab-root' },
                                content: { class: 'accordionTab-content' },
                                transition: { class: 'accordionTab-transition' }
                            }"
                        >
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <img 
                                        class="headerImg"
                                        :src="require(`@/assets/extras/${outsideColor}_square.jpg`)"  
                                    >
                                    <div>
                                        <h3 class="accordionHeaderTitle">Väline värv</h3>
                                        <span>Valitud: {{outsideColor}}</span>
                                    </div>
                                    <p class="extraPrice">+0€</p>
                                </span>
                            </template>
                            <h3 style="margin: 0;">Vali välise lasuuri toon</h3>
                            <div class="selectColor">
                                <div>
                                    <i class="pi pi-check" v-if="outsideColor == 'puit'"></i>
                                    <img 
                                        @click="outsideColor = 'puit'"
                                        :src="require('@/assets/extras/puit_square.jpg')" 
                                    >
                                    <p>Naturaalne</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="outsideColor == 'hall'"></i>
                                    <img 
                                        @click="outsideColor = 'hall'"
                                        :src="require('@/assets/extras/hall_square.jpg')" 
                                    >
                                    <p>Hall</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="outsideColor == 'valge'"></i>
                                    <img 
                                        @click="outsideColor = 'valge'"
                                        :src="require('@/assets/extras/valge_square.jpg')" 
                                    >
                                    <p>Valge</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="outsideColor == 'punane'"></i>
                                    <img 
                                        @click="outsideColor = 'punane'"
                                        :src="require('@/assets/extras/punane_square.jpg')" 
                                    >
                                    <p>Punane</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="outsideColor == 'must'"></i>
                                    <img 
                                        @click="outsideColor = 'must'"
                                        :src="require('@/assets/extras/must_square.jpg')" 
                                    >
                                    <p>Must</p>
                                </div>
                            </div>
                            <img class="productImg" style="margin: 0; margin-bottom: -50px; margin-top: -50px;" :src='listingData.images[0].src'>
                        </AccordionTab>
                        <AccordionTab
                            :pt="{
                                headerAction: { class: 'accordionTab-root' },
                                content: { class: 'accordionTab-content' },
                                transition: { class: 'accordionTab-transition' }
                            }"
                        >
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <img 
                                        class="headerImg"
                                        :src="require(`@/assets/extras/${insideColor}_square.jpg`)" 
                                    >
                                    <div>
                                        <h3 class="accordionHeaderTitle">Sisevärv</h3>
                                        <span>Valitud: {{ insideColor }}</span>
                                    </div>
                                    <p class="extraPrice">+0€</p>
                                </span>
                            </template>
                            <h3 style="margin: 0;">Vali sisemise lasuuri toon</h3>
                            <div class="selectColor">
                                <div>
                                    <i class="pi pi-check" v-if="insideColor == 'puit'"></i>
                                    <img 
                                        @click="insideColor = 'puit'"
                                        :src="require('@/assets/extras/puit_square.jpg')" 
                                    >
                                    <p>Naturaalne</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="insideColor == 'hall'"></i>
                                    <img 
                                        @click="insideColor = 'hall'"
                                        :src="require('@/assets/extras/hall_square.jpg')" 
                                    >
                                    <p>Hall</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="insideColor == 'valge'"></i>
                                    <img 
                                        @click="insideColor = 'valge'"
                                        :src="require('@/assets/extras/valge_square.jpg')" 
                                    >
                                    <p>Valge</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="insideColor == 'punane'"></i>
                                    <img 
                                        @click="insideColor = 'punane'"
                                        :src="require('@/assets/extras/punane_square.jpg')" 
                                    >
                                    <p>Punane</p>
                                </div>
                                <div>
                                    <i class="pi pi-check" v-if="insideColor == 'must'"></i>
                                    <img 
                                        @click="insideColor = 'must'"
                                        :src="require('@/assets/extras/must_square.jpg')" 
                                    >
                                    <p>Must</p>
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab
                            :pt="{
                                headerAction: { class: 'accordionTab-root' },
                                content: { class: 'accordionTab-content' },
                                transition: { class: 'accordionTab-transition' }
                            }"
                        >
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <img 
                                        class="headerImg"
                                        src="https://cupella.ee/wp-content/uploads/2021/10/KomboLux-Puravik-7-e1635342034372.jpeg" 
                                    >
                                    <div>
                                        <h3 class="accordionHeaderTitle">Valgustused</h3>
                                        <span>Valitud: LED valgustused</span>
                                    </div>
                                    <p class="extraPrice">+127€</p>
                                </span>
                            </template>
                            <p class="m-0">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                            </p>
                        </AccordionTab>
                        <AccordionTab
                            :pt="{
                                headerAction: { class: 'accordionTab-root' },
                                content: { class: 'accordionTab-content' },
                                transition: { class: 'accordionTab-transition' }
                            }"
                        >
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <img 
                                        class="headerImg"
                                        :src="require('@/assets/extras/1_gallery.jpg')" 
                                    >
                                    <div>
                                        <h3 class="accordionHeaderTitle">Kerise veepaak</h3>
                                        <span>Valitud: 30l veepaak</span>
                                    </div>
                                    <p class="extraPrice">+150€</p>
                                </span>
                            </template>
                            <p class="m-0">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <ButtonPrime style="width: 100%; height: 50px; margin-top: 25px; background: orange;" type="button" ref="postBtn" label="Esita enda tellimus" icon="pi pi-check"/>
                </div>
            </div>
        </div>
        <h3>Klientide arvustused</h3>
        <Carousel style="padding-bottom: 10px;" :value="clientFeedback" :numVisible="3" :numScroll="3" circular :showNavigators="false" :showIndicators="false">
            <template #item="slotProps">
                <Card class="feedbackCard" style="width: 93%; margin-bottom: 10px;">
                    <template #header>
                    </template>
                    <template #title> {{slotProps.data.name}} </template>
                    <template #subtitle> Cupella Mini-Spa</template>
                    <template #content>
                        <p class="m-0">
                            Lorem ipsum uam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                            quas!
                        </p>
                    </template>
                    <template #footer>
                    </template>
                </Card>
            </template>
        </Carousel>
    </div>
    <OverlayPanel ref="overlayComp">
        <img class="smallImg" :src="require('@/assets/extras/harvia-m3sl.jpg')" />
    </OverlayPanel>
    <Toast ref="toastComp" />
</template>
<script>
import Carousel from 'primevue/carousel';
import Image from 'primevue/image';
import Card from 'primevue/card';
import OverlayPanel from 'primevue/overlaypanel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Divider from 'primevue/divider';
import Slider from 'primevue/slider';
import InputNumber from 'primevue/inputnumber';
import Toast from 'primevue/toast';

export default {
    name: 'singleListingPage',
   
    components: {
        Carousel,
        Image,
        Card,
        OverlayPanel,
        Accordion,
        AccordionTab,
        Divider,
        Slider,
        InputNumber,
        Toast
    },
    props: {
    },
    watch: {
       
    },

    data () {
        return {
            listingData: null,
            imagesCarousel: [],
            terracePrice: 90,
            terraceSize: 0,
            confirmedTerraceSize: 0,
            confirmedTerraceExtraCost: 0,
            outsideColor: 'puit',
            confirmedOutsideColor: 'puit',
            insideColor: 'puit',
            activeAccordionIndex: -1,
            clientFeedback: [
                {
                    name: 'Kadri Tammsalu'
                },
                {
                    name: 'Peeter Toomingas'
                },
                {
                    name: 'Helgi Valjut'
                }
            ]
        }
    },
    methods: {
        updateIndex(index) {
            this.activeAccordionIndex = index
        },
        confirmExtra(extra) {
            if(extra == 'terrace') {
                this.confirmedTerraceSize = this.terraceSize
                this.confirmedTerraceExtraCost = this.terracePrice * this.terraceSize
                this.activeAccordionIndex ++
                this.$refs.toastComp.add({ severity: 'success', summary: 'Valik kinnitatud!', life: 3000 })
            }
            if(extra == 'outsideColor') {
                this.confirmedOutsideColor = this.outsideColor
                this.activeAccordionIndex ++
                this.$refs.toastComp.add({ severity: 'success', summary: 'Valik kinnitatud!', life: 3000 })
            }

        },

        showImage(event) {
            this.$refs.overlayComp.toggle(event);
        },
        
        getWooCommerceProducts: async function() {
            // Set your WooCommerce API credentials
            const consumerKey = 'ck_523a404faf7a65ad08551941adf3981d97d77d56';
            const consumerSecret = 'cs_beb6df010e20118201026eac96d008c1da3277cb';

            // Set your WooCommerce store URL
            const storeUrl = 'https://cupella.ee';

            // API endpoint for retrieving products
            const apiEndpoint = `${storeUrl}/wp-json/wc/v3/products/3710`;

            // Set query parameters (optional)
            const queryParams = {
                per_page: 100, // Number of products per page
                status: 'publish',
            };

            // Set up the authentication headers
            const headers = {
                Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
            };

            /// Construct the URL with query parameters
            const url = new URL(apiEndpoint);
            url.search = new URLSearchParams(queryParams);

            // Make the API request using fetch
            fetch(url, { headers })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${ response.status }`);
                }
                return response.json();
            })
            .then(data => {
                this.listingData = data
                this.imagesCarousel = []
                for(const element of data.images) {
                    this.imagesCarousel.push(element)
                }
                this.imagesCarousel.shift()
                this.imagesCarousel.pop()

            })
            .catch(error => {
                console.error('Error:', error.message);
            });
        },
        
    },
    created() {
        this.getWooCommerceProducts()
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600&family=Oswald:wght@300&display=swap');

.container {
    width: 1040px;
    min-height: 1000px;
    margin: 0 auto;
    padding-bottom: 75px;
}
.head {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.productTitle {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 300;
    font-size: 36px;
    color: rgb(50,50,50);
}
.price h2 {
    margin: 0;
    margin-top: -5px;
    margin-bottom: 10px;
    font-size: 36px;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    color: rgb(50,50,50);
}
h3 {
    margin-top: 0;
    font-family: 'Nunito Sans', sans-serif;
    color: rgb(50,50,50);
}
span {
    font-family: 'Nunito Sans', sans-serif;
}
.split {
    position: relative;
    width: 50%;
    padding-right: 3%;
}
.split:last-child {
    width: 47%;
    padding-right: 0%;
}
.description {
    font-size: 15px;
    color: rgb(50,50,50);
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
}
.productImg {
    width: 100%;
    margin-top: -120px;
    margin-left: -22px;
}
.variationSelect {
    position: relative;
    margin-top: -66px;
    width: 93%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 1px solid rgb(230,230,230);
    padding-top: 5px;
    margin-left: 0px;
}
.variationSelect div {
    position: relative;
    width: 18%;
    height: 50px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.variationSelect div:hover {
    cursor: pointer;
    height: 60px;
    margin-top: -10px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.variationSelect div i {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 14px;
    color: rgb(100,100,100);
    font-weight: bold;
    background: white;
}
.variationSelect p {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    color: rgb(150,150,150);
}
.variationSelect img {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.4);
    border-bottom: none;
    border-radius: 10px 10px 0px 0px;
}
.variationSelect span {
    position: absolute;
    top: -28px;
    right: 0;
    color: steelblue;
    cursor: pointer;
}

.params {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-left: -12px;
}
.singleParam {
    width: 25%;
    margin-bottom: 10px;
    text-align: center;
}
.singleParam p {
    margin: 0;
    margin-bottom: 3px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: rgb(150,150,150);
}
.singleParam p:last-child {
    margin-top: 3px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    color: rgb(50,50,50);
    font-weight: 600;
}
.singleParam img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    opacity: .6;
}
.paramIconContainer {
    position: relative;
    height: 60px;
    width: 100px;
    margin: 0 auto;
    border: 1px solid rgb(230,230,230);
    border-radius: 35px;
    background: white;
}
.p-button {
    margin-top: 10px;
    width: 55.3%;
    border: none;
    background: rgb(209, 168, 114);
}
.p-button:last-child {
    width: 40%;
    background: steelblue;
    border: none;
}
.smallTitle {
    font-size: 18px;
    text-decoration: underline;
    color: rgb(100,100,100);
}
.incIcons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.singleIncluded {
    margin-top: 10px;
    position: relative;
    width: 31%;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(230,230,230);
    background: white;
    border-radius: 10px;
}
.singleIncluded p {
    margin: 0;
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
}
.imageContainer {
    text-align: center;
}
.singleIncluded img {
    opacity: .7;
    margin-left: auto;
    width: 50px;
}
.singleIncluded:nth-child(1) img {
    margin-top: 2px;
    margin-bottom: 1px;
}
.singleIncluded:nth-child(3) img {
    margin-top: 2px;
    margin-bottom: 1px;
}
.singleIncluded i {
    text-align: center;
    width: 100%;
}
.image {
    margin-top: 5px;
    overflow: hidden;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
}
.image:first-child {
    margin-left: 3px;
}
.feedbackCard {
    margin-top: 10px;
    margin-left: 3px;
}
.smallImg {
    width: 200px;
}
.accordionImages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.accordionImages img {
    width: 47%;
    height: 200px;
    border-radius: 10px;
}
.accordion-root {
    border: none;
}
.accordionTab {
    margin-bottom: 15px;
}
.headerImg {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}
.accordionHeaderTitle {
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
}
.extraPrice {
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 5px;
    font-weight: bold;
    color: rgb(0, 170, 128);
}
#stickySection {
    position: sticky;
    top: 50px;
}
.selectColor {
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.selectColor div {
    position: relative;
    width: 80px;
}
.selectColor img {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    cursor: pointer;
}
.selectColor div i {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
    border-radius: 100%;
    top: 0;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 14px;
    color: rgb(100,100,100);
    font-weight: bold;
    background: white;
}
.selectColor p {
    text-align: center;
    margin: 0;
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
}
</style>
<style>
.p-accordion-header {
    margin-top: 10px;
}
.accordionTab-root {
    border: 1px solid rgb(230,230,230);
    border-radius: 10px;
}
.accordionTab-content {
    border: 1px solid rgb(230,230,230);
    border-radius: 10px;
    margin-top: 5px;
}
.p-inputnumber-input {
    width: 180px;
}
.accordionTab-transition {
    -webkit-transition: .07s ease-in;
    -o-transition: .07s ease-in;
    transition: .07s ease-in;
}
</style>